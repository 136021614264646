import React from "react";
import Columns from "../../components/AccountComponents/Columns/Columns";
import Deposits from "../../components/AccountComponents/Deposits/Deposits";
import Faq from "../../components/AccountComponents/Faq/Faq";
import Fees from "../../components/AccountComponents/Fees/Fees";
import First from "../../components/AccountComponents/First/First";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import classes from "./AccountPage.module.scss";

const AccountPage = () => {
  return (
    <div className={classes.account}>
      <Header />
      <First />
      <Columns />
      <Deposits />
      <Fees />
      <Faq />
      <Footer />
    </div>
  );
};

export default AccountPage;
