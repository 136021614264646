import AccountPage from "../pages/AccountPage/AccountPage";
import EducationPage from "../pages/EducationPage/EducationPage";
import MainPage from "../pages/MainPage/MainPage";
import StartPage from "../pages/StartPage/StartPage";
import TradingSpacePage from "../pages/TradingSpacePage/TradingSpacePage";
import AboutPage from "../pages/AboutPage/AboutPage";


export const routes = [
    { path: '/', element: MainPage },
    { path: '/start', element: StartPage },
    { path: '/accounts', element: AccountPage },
    { path: "/education", element: EducationPage },
    { path: "/trading", element: TradingSpacePage },
    { path: "/about", element: AboutPage },
];

export const headerRoutes = [
    { id: 1, path: '/start', text: 'header__item1' },
    { id: 2, path: '/accounts', text: 'header__item2' },
    { id: 3, path: '/trading', text: 'header__item3' },
    { id: 4, path: '/education', text: 'header__item4' },
    { id: 5, path: '/about', text: 'header__item5' },
];
