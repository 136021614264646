import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./ReadyTo.module.scss";
import { useTranslation } from "react-i18next";
const ReadyTo = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.readyTo}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("start_nine__title")}
        </div>
        <div className={classes.btns}>
          <Button>{t("start_nine__open")}</Button>
          <Button>{t("start_nine__demo")}</Button>
        </div>
      </div>
    </div>
  );
};

export default ReadyTo;
