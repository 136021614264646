import { BrowserRouter } from "react-router-dom";
import Router from "./components/GlobalComponents/Router/AppRouter";
import './App.scss';
import { useState, useEffect } from "react";
import { RotatingSquare } from "react-loader-spinner";

function App() {
  const [ loading, setLoading ] = useState(true);
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => setLoading(false), 3000)
    };
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
    }
  }, []);
  return (
    <div >
      <BrowserRouter>
        {loading &&
          <div className="preloader">
            <RotatingSquare
              height="100"
              width="100"
              color="#57E9EB"
              ariaLabel="rotating-square-loading"
              strokeWidth="4"
              visible={true}
            />
          </div>
        }
        <Router />
      </BrowserRouter>
    </div>
  );
}

export default App;
