import React from "react";
import classes from "../RangeTrading.module.scss";
import { useTranslation } from "react-i18next";
const Six = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.rangeTradingWrap}>
      <h3 className={["header-default", classes.rangeTradingTitle].join(" ")}>
        {t("main_nine__title6")}
      </h3>
      <p className={["text-default", classes.rangeTradingText].join(" ")}>
        {t("main_nine__subtitle6")}
      </p>
    </div>
  );
};

export default Six;
