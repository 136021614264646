import React from "react";
import classes from "./Footer.module.scss";
import { Trans, useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  
  return (
    <div className="container">
      <div className={classes.footer}>
        <div className={classes.col_01}>
          <div className={classes.row}>
            <div className={classes.header}>{t("footer_left__title1")}</div>
            <div className={classes.text}>
              <Trans>{t("footer_left__subtitle1")}</Trans>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.header}>{t("footer_left__title2")}</div>
            <div className={classes.text}>
              <Trans>{t("footer_left__subtitle2")}</Trans>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.text}>{t("footer_left__item1")}</div>
            <div className={classes.text}>{t("footer_left__item2")}</div>
            <div className={classes.text}>{t("footer_left__item3")}</div>
          </div>
        </div>
        <div className={classes.col_02}>
          <div>
            <a href="/docs/PRIVACY_POLICY.pdf" target="_blank">
              {t('footer_privacy_policy')}
            </a>
          </div>
          <div className={classes.row}>
            <a href="/docs/AML_POLICY.pdf" target="_blank">
              {t('footer_aml')}
            </a>
          </div>
          <div className={classes.row}>
            <a href="/docs/TERMS_&_CONDITIONS.pdf" target="_blank">
              {t('footer_terms')}
            </a>
          </div>
        </div>
        <div className={classes.col_03}>
          <div>{t("footer_right__title")}</div>
          <div className={classes.row}>
            {t("footer_right__subtitle")}
            <br />
            {/* {t("footer_right__item1")} */}
            <a href="tel:+441519479676">+441519479676</a>
            <br />
            <a href="mailto:support@asset-ace.org">support@asset-ace.org</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
