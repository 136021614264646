import React from "react";
import Education from "../../components/EducationComponents/Education/Education";
import Practice from "../../components/EducationComponents/Practice/Practice";
import Trading from "../../components/EducationComponents/Trading/Trading";
import Spreads from "../../components/EducationComponents/Spreads/Spreads";
import What from "../../components/EducationComponents/What/What";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import classes from "./EducationPage.module.scss";

const EducationPage = () => {
  return (
    <div className={classes.education}>
      <Header />
      <Education />
      <Practice />
      <Trading />
      <Spreads />
      <What />
      <Footer />
    </div>
  );
};

export default EducationPage;
