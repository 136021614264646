import React, {useState} from "react";
import classes from "./RangeTrading.module.scss";
import img from "../../../assets/img/MainPage/range-trading-img.png"

import First from "./Tabs/First";
import Second from "./Tabs/Second";
import Third from "./Tabs/Third";
import Fourth from "./Tabs/Fourth";
import Five from "./Tabs/Five";
import Six from "./Tabs/Six";

const RangeTrading = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTab = (e) => {
    setActiveTab(e.target.classList[0])
  };
  return (
    <div className="container">
      <div className={classes.rangeTradingRow}>
        <div className={classes.rangeTradingCol6}>
          {activeTab === "tab1" ? <First/> : activeTab === "tab2" ? <Second/> : activeTab === "tab3" ?
            <Third/> : activeTab === "tab4" ? <Fourth/> : activeTab === 'tab5' ? <Five/> : <Six/>}
          <ul className={classes.rangeTradingNav}>
            <li
              className={[activeTab === "tab1" ? classes.active : "", "tab1"].join(' ')}
              onClick={handleTab}
            >
              1
            </li>
            <li
              className={[activeTab === "tab2" ? classes.active : "", "tab2"].join(' ')}
              onClick={handleTab}
            >
              2
            </li>
            <li
              className={[activeTab === "tab3" ? classes.active : "", "tab3"].join(' ')}
              onClick={handleTab}
            >
              3
            </li>
            <li
              className={[activeTab === "tab4" ? classes.active : "", "tab4"].join(' ')}
              onClick={handleTab}
            >
              4
            </li>
            <li
              className={[activeTab === "tab5" ? classes.active : "", "tab5"].join(' ')}
              onClick={handleTab}
            >
              5
            </li>
            <li
              className={[activeTab === "tab6" ? classes.active : "", "tab6"].join(' ')}
              onClick={handleTab}
            >
              6
            </li>
          </ul>
        </div>
        <div className={classes.rangeTradingCol6}>
          <img src={img} className={classes.imgFluid} alt=""/>
        </div>
      </div>
    </div>
  )
}

export default RangeTrading;
