import React, { useRef, useEffect } from "react";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/education/2/2.png";
import classes from "./Trading.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
const Trading = () => {
  const { t } = useTranslation();
  const refLeft = useRef(null);
  const refRight = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const elementLeft = refLeft.current;
    const elementRight = refRight.current;
    if (document.documentElement.clientWidth > 992) {
      gsap.timeline().fromTo(
        elementLeft,
        {
          y: 300,
        },
        {
          y: 0,
          scrollTrigger: {
            trigger: elementLeft,
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        elementRight,
        {
          x: -300,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: elementRight,
            start: "top bottom",
            end: "top center",
            scrub: 3,
            id: "scrub",
          },
        }
      );
    }
  }, []);

  return (
    <div className={classes.practice}>
      <div className={classes.practiceBody}>
        <div className="container">
          <div className={classes.practiceRow}>
            <div className={classes.practiceRowRight} ref={refRight}>
              <img src={img1} alt="" />
            </div>
            <div className={classes.practiceRowLeft} ref={refLeft}>
              <div
                className={[classes.practiceRowLeftTitle, "title"].join(" ")}
              >
                {t("education_three__title")}
              </div>
              <div
                className={[classes.practiceRowLeftSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_three__item1")}
              </div>
              <div
                className={[classes.practiceRowLeftSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_three__item2")}
              </div>
              <div
                className={[classes.practiceRowLeftSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_three__item3")}
              </div>
              <div
                className={[classes.practiceRowLeftSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_three__item4")}
              </div>
              <div className={classes.practiceRowLeftStart}>
                <Button></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trading;
