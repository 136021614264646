import React from "react";
import classes from "./OurAdvantages.module.scss";
import { useTranslation } from "react-i18next";
const OurAdvantages = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.ourAdvantagesSection}>
      <div className="container">
        <div className={classes.ourAdvantagesRow}>
          <div className={classes.ourAdvantagesCol6}>
            <div className={classes.ourAdvantagesWrap}>
              <h4
                className={[classes.ourAdvantagesTitle, "header-default"].join(
                  " "
                )}
              >
                {t("main_second__title")}
              </h4>
              <ul className={classes.ourAdvantagesList}>
                <li className={[classes.ourAdvantagesItem]}>
                  {t("main_second__item1")}
                </li>
                <li className={[classes.ourAdvantagesItem]}>
                  {t("main_second__item2")}
                </li>
                <li className={[classes.ourAdvantagesItem]}>
                  {t("main_second__item3")}
                </li>
                <li className={[classes.ourAdvantagesItem]}>
                  {t("main_second__item4")}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurAdvantages;
