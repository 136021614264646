import React from "react";
import classes from "./BuiltBy.module.scss";
import icon_01 from "../../../assets/img/AboutPage/built_by_icon_01.png";
import icon_02 from "../../../assets/img/AboutPage/built_by_icon_02.png";
import icon_03 from "../../../assets/img/AboutPage/built_by_icon_03.png";
import icon_04 from "../../../assets/img/AboutPage/built_by_icon_04.png";
import light_01 from "../../../assets/img/TradingSpacePage/getstarted_light_01.png";
import light_02 from "../../../assets/img/AboutPage/light_blue.png";
import light_03 from "../../../assets/img/TradingSpacePage/getstarted_light_03.png";
import light_04 from "../../../assets/img/TradingSpacePage/getstarted_light_04.png";
import { Trans, useTranslation } from "react-i18next";
const cards = [
  {
    id: 1,
    icon: icon_01,
    light: light_01,
    text: "about_second__item1",
  },
  {
    id: 2,
    icon: icon_02,
    light: light_02,
    text: "about_second__item2",
  },
  {
    id: 3,
    icon: icon_03,
    light: light_03,
    text: "about_second__item3",
  },
  {
    id: 4,
    icon: icon_04,
    light: light_04,
    text: "about_second__item4",
  },
];

const BuiltBy = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.builtBy}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("about_second__title")}
        </div>
        <div className={classes.text}>
          <Trans>{t("about_second__subtitle")}</Trans>
        </div>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div className={classes.card} key={card.id}>
              <img className={classes.cardLight} src={card.light} alt="" />
              <div className={classes.cardContent}>
                <img className={classes.cardIcon} src={card.icon} alt="" />
                <div className={classes.cardText}>{t(card.text)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuiltBy;
