import React from "react";
import classes from "./Fees.module.scss";
import img1 from "../../../assets/img/accounts/3/1.png";
import { useTranslation } from "react-i18next";
const Fees = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.fees}>
      <div className={classes.feesBody}>
        <div className="container">
          <div className={classes.feesRow}>
            <div className={classes.feesRowLeft}>
              <div className={[classes.feesTitle, "title"].join(" ")}>
                {t("accounts_four__title")}
              </div>
              <div className={classes.feesBlock}>
                <div className={classes.feesBlockTitle}>
                  {t("accounts_four1__subtitle")}
                </div>
                <div className={classes.feesBlockSubtitle}>
                  {t("accounts_four1__text")}
                </div>
              </div>
              <div className={classes.feesBlock}>
                <div className={classes.feesBlockTitle}>
                  {t("accounts_four2__subtitle")}
                </div>
                <div className={classes.feesBlockSubtitle}>
                  {t("accounts_four2__text")}
                </div>
              </div>
              <div className={classes.feesBlock}>
                <div className={classes.feesBlockTitle}>
                  {t("accounts_four3__subtitle")}
                </div>
                <div className={classes.feesBlockSubtitle}>
                  {t("accounts_four3__text")}
                </div>
              </div>
            </div>
            <div className={classes.feesRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fees;
