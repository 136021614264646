import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./AlwaysGiving.module.scss";
import { useTranslation } from "react-i18next";
const cards = [
  {
    id: 0,
    title: "start_second_item1__title",
    desc: "start_second_item1__subtitle",
    btnText: "start_second_item1__btn",
  },
  {
    id: 1,
    title: "start_second_item2__title",
    desc: "start_second_item2__subtitle",
    btnText: "start_second_item2__btn",
  },
  {
    id: 2,
    title: "start_second_item3__title",
    desc: "start_second_item3__subtitle",
    btnText: "start_second_item3__btn",
  },
];

const AlwaysGiving = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.alwaysGiving}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("start_second__title")}
        </div>
        <div className={[classes.text, "text-default"].join(" ")}>
          {t("start_second__subtitle")}
        </div>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div className={classes.card} key={card.id}>
              <div className={classes.cardTitle}>{t(card.title)}</div>
              <div className={classes.cardDesc}>{t(card.desc)}</div>
              <div className={classes.cardBtn}>
                <Button>{t(card.btnText)}</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AlwaysGiving;
