import React from "react";
import classes from "./Banner.module.scss";
import phone from "../../../assets/img/TradingSpacePage/banner_comp.png";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
const Banner = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.bannerStart}>
        <div className={classes.header}>{t("trading_first__title")}</div>
        <div className={classes.text}>{t("trading_first__subtitle")}</div>
        <div className={classes.btn}>
          <Button />
        </div>
        <img
          className={[classes.image, "no-select"].join(" ")}
          src={phone}
          alt=""
        />
      </div>
    </div>
  );
};

export default Banner;
