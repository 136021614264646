import React from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import StartApp from "../../components/MainPage/StartApp/StartApp";
import Banner from "../../components/TradingSpacePage/Banner/Banner";
import QuickFacts from "../../components/TradingSpacePage/GetStarted/GetStarted";
import ManageWallet from "../../components/TradingSpacePage/ManageWallet/ManageWallet";
import classes from './TradingSpacePage.module.scss';

const TradingSpacePage = () => {
    return (
        <div className='relative z-1'>
            <div className={classes.pageBG}></div>
            <Header />
            <Banner />
            <QuickFacts />
            <ManageWallet />
            <StartApp />
            <Footer />
        </div>
    );
};

export default TradingSpacePage;
