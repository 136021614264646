import React from "react";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Header from "../../components/GlobalComponents/Header/Header";
import classes from './AboutPage.module.scss';
import Banner from '../../components/AboutPage/Banner/Banner';
import BuiltBy from "../../components/AboutPage/BuiltBy/BuiltBy";
import AwardWinning from "../../components/AboutPage/AwardWinning/AwardWinning";
import ReadyTo from '../../components/StartPage/ReadyTo/ReadyTo';
import QA from "../../components/AboutPage/QA/QA";
import StartApp from "../../components/MainPage/StartApp/StartApp";

const AboutPage = () => {
    return (
        <div className='relative z-1'>
            <div className={classes.pageBG}></div>
            <Header />
            <Banner />
            <BuiltBy />
            <AwardWinning />
            <ReadyTo />
            <StartApp />
            <QA />
            {/* Do you have any questions? */}
            <Footer />
        </div>
    );
};

export default AboutPage;
