import React, { useState } from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./First.module.scss";
import img1 from "../../../assets/img/accounts/1/2.png";
import { useTranslation } from "react-i18next";
const First = () => {
  const { t } = useTranslation();
  const [anim, setAnim] = useState(false);

  return (
    <div className={classes.first}>
      <div className={classes.firstBody}>
        <div className="container">
          <div className={classes.firstRow}>
            <div className={classes.firstRowLeft}>
              <div className={classes.firstTitle}>
                {t("accounts_first__title")}
              </div>
              <div className={classes.firstStart}>
                <Button
                  onMouseEnter={() => {
                    setAnim(true);
                  }}
                  onMouseLeave={() => {
                    setAnim(false);
                  }}
                ></Button>
              </div>
            </div>
            <div
              className={
                anim
                  ? [classes.firstRowRight, classes.active].join(" ")
                  : classes.firstRowRight
              }
            >
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
