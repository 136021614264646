import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import classes from "./Banner.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
import phone from "../../../assets/img/MainPage/hero-banner.png";
import coin1 from "../../../assets/img/MainPage/coin-1.png";
import coin2 from "../../../assets/img/MainPage/coin-2.png";
import coin3 from "../../../assets/img/MainPage/coin-3.png";
import coin4 from "../../../assets/img/MainPage/coin-4.png";

const Banner = () => {
  const ref = useRef(null);
  const { t } = useTranslation();
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    if (ref !== null) {
      gsap
        .timeline()
        .to(".coin-img-1", {
          y: 1100,
          scrollTrigger: {
            trigger: ".coin-img-1",
            start: "top 100px",
            end: "bottom 0px",
            scrub: 3,
            id: "scrub",
          },
        })
        .to(".coin-img-2", {
          y: 1100,
          scrollTrigger: {
            trigger: ".coin-img-2",
            start: "top 0px",
            end: "bottom 0px",
            scrub: 3,
            id: "scrub",
          },
        })
        .to(".coin-img-3", {
          y: 800,
          x: 200,
          scrollTrigger: {
            trigger: ".coin-img-3",
            start: "top 0px",
            end: "bottom 0px",
            scrub: 1,
            id: "scrub",
          },
        })
        .to(".coin-img-4", {
          y: 1000,
          x: -50,
          scrollTrigger: {
            trigger: ".coin-img-4",
            start: "top 0px",
            end: "bottom 0px",
            scrub: 1,
            id: "scrub",
          },
        })
        .to(".phone-img", {
          y: 850,
          scale: 0.7,
          scrollTrigger: {
            trigger: ".phone-img",
            start: "top 100px",
            end: "bottom 0px",
            scrub: 3,
            id: "scrub",
          },
        });
    }
    const addClassImg = (el) => {
      document.querySelector(`.${el}`).classList.add(`hover-${el}`);
    };
    const removeClassImg = (el) => {
      document.querySelector(`.${el}`).classList.remove(`hover-${el}`);
    };

    document.querySelector("#hoverLink").addEventListener("mousemove", (e) => {
      addClassImg("coin-img-1");
      addClassImg("coin-img-2");
      addClassImg("coin-img-3");
      addClassImg("coin-img-4");
    });
    document.querySelector("#hoverLink").addEventListener("mouseleave", (e) => {
      removeClassImg("coin-img-1");
      removeClassImg("coin-img-2");
      removeClassImg("coin-img-3");
      removeClassImg("coin-img-4");
    });
  }, []);
  return (
    <div className={classes.bannerSection}>
      <div className="container">
        <div className={classes.bannerRow}>
          <div className={classes.bannerCol6}>
            <div className={classes.bannerTitle}>{t("main_first__title")}</div>
            <div className={classes.bannerText}>
              {t("main_first__subtitle")}
            </div>
            <div className={classes.btn}>
              <Button id={"hoverLink"} />
            </div>
          </div>
          <div className={classes.bannerCol6}>
            <img
              src={coin2}
              className={[classes.coin1, "coin-img-1"].join(" ")}
              alt=""
            />
            <img
              src={coin3}
              className={[classes.coin2, "coin-img-2"].join(" ")}
              alt=""
            />
            <div className={classes.imageWrap}>
              <img
                className={[classes.imgFluid, "phone-img"].join(" ")}
                src={phone}
                alt=""
              />
            </div>
            <img
              src={coin1}
              className={[classes.coin3, "coin-img-3"].join(" ")}
              alt=""
            />
            <img
              src={coin4}
              className={[classes.coin4, "coin-img-4"].join(" ")}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
