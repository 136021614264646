import React from "react";
import classes from "./ChooseProminent.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
const ChooseProminent = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.chooseProminentWrap}>
        <h2
          className={["header-default", classes.chooseProminentTitle].join(" ")}
        >
          {t("main_eight__title")}
        </h2>
        <p className={["text-default", classes.chooseProminentText].join(" ")}>
          {t("main_eight__subtitle1")}
        </p>
        <Button classesName={classes.chooseProminentLink}>
          {t("btn_start_now")}
        </Button>
      </div>
    </div>
  );
};

export default ChooseProminent;
