import React from "react";
import classes from "./DiscoverMore.module.scss";
import icon_01 from "../../../assets/img/StartPage/discover_01.png";
import icon_02 from "../../../assets/img/StartPage/discover_02.png";
import icon_03 from "../../../assets/img/StartPage/discover_03.png";
import icon_04 from "../../../assets/img/StartPage/discover_04.png";
import icon_05 from "../../../assets/img/StartPage/discover_05.png";
import { useTranslation } from "react-i18next";
const cards = [
  {
    id: 0,
    icon: icon_01,
    text: "start_eight__item1",
  },
  {
    id: 1,
    icon: icon_02,
    text: "start_eight__item2",
  },
  {
    id: 2,
    icon: icon_03,
    text: "start_eight__item3",
  },
  {
    id: 3,
    icon: icon_04,
    text: "start_eight__item4",
  },
  {
    id: 4,
    icon: icon_05,
    text: "start_eight__item5",
  },
];

const DiscoverMore = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.whatEnergizes}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("start_eight__title")}
        </div>
        <div className={classes.cards}>
          {cards.map((card) => (
            <div className={classes.card} key={card.id}>
              <img
                className={[classes.icon, "no-select"].join(" ")}
                src={card.icon}
                alt=""
              />
              <div className={[classes.text, "text-default"].join(" ")}>
                {t(card.text)}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DiscoverMore;
