import React from "react";
import classes from "./Spreads.module.scss";
import { useTranslation } from "react-i18next";
const Spreads = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.spreads}>
      <div className={classes.spreadsBody}>
        <div className="container">
          <div className={classes.spreadsRow}>
            <div className={classes.spreadsRowLeft}>
              <div className={[classes.spreadsRowLeftTitle, "title"].join(" ")}>
                {t("education_four__title")}
              </div>
              <div className={classes.spreadsRowLeftSub}>
                {t("education_four__subtitle")}
              </div>
              <div
                className={[classes.spreadsRowLeftSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_four__item1")}
              </div>
              <div
                className={[classes.spreadsRowLeftSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_four__item2")}
              </div>
              <div
                className={[classes.spreadsRowLeftSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_four__item3")}
              </div>
            </div>
            <div className={classes.spreadsRowRight}>
              <div
                className={[
                  classes.spreadsRowLeftTitle,
                  classes.hide,
                  "title",
                ].join(" ")}
              >
                {t("education_four__title")}
              </div>
              <div className={classes.spreadsRowRightTitle}>
                {t("education_four_right__title")}
              </div>
              <div
                className={[classes.spreadsRowRightSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_four_right__item1")}
              </div>
              <div
                className={[classes.spreadsRowRightSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_four_right__item2")}
              </div>
              <div className={classes.spreadsRowRightWhiteText}>
                {t("education_four_right__item3")}
              </div>
              <div
                className={[classes.spreadsRowRightSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_four_right__item4")}
              </div>
              <div
                className={[classes.spreadsRowRightSubtitle, "subtitle"].join(
                  " "
                )}
              >
                {t("education_four_right__item5")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Spreads;
