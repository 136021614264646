import React, { useState } from "react";
import classes from "./StartApp.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";

const StartApp = () => {
  const { t } = useTranslation();
  const [privacyPolicyAgree, setPrivacyPolicyAgree] = useState(false);
  const [promo, setPromo] = useState(false);
  return (
    <div className="container">
      <div className={classes.startAppRow}>
        <div className={classes.startAppCol7}>
          <h2
            className={[
              "header-default",
              classes.startAppTitle,
              classes.mb40,
            ].join(" ")}
          >
            {t("main_ten_left__title")}
          </h2>
          <div className={classes.startAppForm}>
            <div className={classes.startAppWrapInput}>
              <div className={classes.col6}>
                <input
                  type="text"
                  name="firstName"
                  placeholder={t("main_ten_left__first")}
                  className={[classes.startAppInput, classes.me15].join(" ")}
                />
              </div>
              <div className={classes.col6}>
                <input
                  type="text"
                  name="middleName"
                  placeholder={t("main_ten_left__middle")}
                  className={[classes.startAppInput, classes.ms15].join(" ")}
                />
              </div>
            </div>
            <div className={classes.startAppWrapInput}>
              <input
                type="text"
                name="lastName"
                placeholder={t("main_ten_left__last")}
                className={classes.startAppInput}
              />
            </div>
            <div className={classes.startAppWrapInput}>
              <div className={classes.col6}>
                <select
                  name="country"
                  defaultValue={"-"}
                  className={[
                    classes.startAppSelect,
                    classes.startAppCol6Input,
                    classes.me15,
                  ].join(" ")}
                >
                  <option value="-" disabled>
                    {t("main_ten_left__country")}
                  </option>
                </select>
              </div>
              <div className={classes.col6}>
                <div className={classes.startAppWrapPhoneInput}>
                  <select
                    name="phoneCode"
                    defaultValue="+91"
                    className={[
                      classes.startAppSelect,
                      classes.startAppCol3Input,
                    ].join(" ")}
                  >
                    <option value="+91">
                      +91
                    </option>
                  </select>
                  <input
                    type="tel"
                    name="phone"
                    placeholder={t("main_ten_left__phone")}
                    className={classes.startAppCol9Input}
                  />
                </div>
              </div>
            </div>
            <div className={classes.startAppWrapInput}>
              <div className={classes.col6}>
                <input
                  type="email"
                  name="email"
                  placeholder={t("main_ten_left__address")}
                  className={[classes.startAppInput, classes.me15].join(" ")}
                />
              </div>
              <div className={classes.col6}>
                <select
                  name="typeAcc"
                  defaultValue={'-'}
                  className={[
                    classes.startAppSelect,
                    classes.startAppInput,
                    classes.ms15,
                  ].join(" ")}
                >
                  <option value="-" disabled>
                    {t("main_ten_left__type")}
                  </option>
                </select>
              </div>
            </div>
            <div
              className={[
                classes.startAppCheckboxWrap,
                privacyPolicyAgree && classes.active,
              ].join(" ")}
              onClick={() => setPrivacyPolicyAgree(!privacyPolicyAgree)}
            >
              <span></span>
              {t("main_ten_left__text1")}
            </div>
            <div
              className={[
                classes.startAppCheckboxWrap,
                promo && classes.active,
              ].join(" ")}
              onClick={() => setPromo(!promo)}
            >
              <span></span>
              {t("main_ten_left__text2")}
            </div>
            <Button classesName={classes.startAppLink}>
              {t("main_ten_left__submit")}
            </Button>
          </div>
        </div>
        <div className={classes.startAppCol5}>
          <h2 className={["header-default", classes.startAppTitle].join(" ")}>
            {t("main_ten_right__title")}
          </h2>
          <h5 className={classes.startAppSubTitle}>
            {t("main_ten_right__subtitle1")}
          </h5>
          <p className={["text-default", classes.startAppSubText].join(" ")}>
            {t("main_ten_right__text1")}
          </p>
          <h5 className={classes.startAppSubTitle}>
            {t("main_ten_right__subtitle2")}
          </h5>
          <p className={["text-default", classes.startAppSubText].join(" ")}>
            {t("main_ten_right__text2")}
          </p>
          <h5 className={classes.startAppSubTitle}>
            {t("main_ten_right__subtitle3")}
          </h5>
          <p className={["text-default", classes.startAppSubText].join(" ")}>
            {t("main_ten_right__text3")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default StartApp;
