import React from "react";
import classes from "./TopReasons.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img from "../../../assets/img/MainPage/top-reasons-img.png";
import { Trans, useTranslation } from "react-i18next";
import img1 from "../../../assets/img/MainPage/top-reasons-number-img1.png";
import img2 from "../../../assets/img/MainPage/top-reasons-number-img2.png";
import img3 from "../../../assets/img/MainPage/top-reasons-number-img3.png";

const TopReasons = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.topReasonsSection}>
      <div className="container">
        <div className={classes.topReasonsRow}>
          <div className={classes.topReasonsCol6}>
            <div className={classes.topReasonsWrap}>
              <h3
                className={["header-default", classes.topReasonsTitle].join(
                  " "
                )}
              >
                <Trans>{t("main_four__title")}</Trans>
              </h3>
              <h6 className={classes.topReasonsSubTitle}>
                {t("main_four__subtitle1")}
              </h6>
              <p className={["text-default", classes.topReasonsText].join(" ")}>
                {t("main_four__text1")}
              </p>
              <h6 className={classes.topReasonsSubTitle}>
                {t("main_four__subtitle2")}
              </h6>
              <p className={["text-default", classes.topReasonsText].join(" ")}>
                {t("main_four__text2")}
              </p>
              <h6 className={classes.topReasonsSubTitle}>
                {t("main_four__subtitle3")}
              </h6>
              <p
                className={[
                  "text-default",
                  classes.topReasonsText,
                  classes.topReasonsLastText,
                ].join(" ")}
              >
                {t("main_four__text3")}
              </p>
              <Button>{t("btn_start_now")}</Button>
            </div>
          </div>
          <div className={classes.topReasonsCol6}>
            <img src={img} className={classes.imgFluid} alt="" />
          </div>
          <div className={classes.topReasonsCol4}>
            <div className={classes.topReasonsWrap}>
              <div className={classes.topReasonsNumber}>
                <img src={img1} className={classes.imgFluid} alt="" />
              </div>
              <p className={classes.topReasonsNumberText}>
                <Trans>{t("main_four__item1")}</Trans>
              </p>
            </div>
          </div>
          <div className={classes.topReasonsCol4}>
            <div className={classes.topReasonsWrap}>
              <div className={classes.topReasonsNumber}>
                <img src={img2} className={classes.imgFluid} alt="" />
              </div>
              <p className={classes.topReasonsNumberText}>
                <Trans>{t("main_four__item2")}</Trans>
              </p>
            </div>
          </div>
          <div className={classes.topReasonsCol4}>
            <div className={classes.topReasonsWrap}>
              <div className={classes.topReasonsNumber}>
                <img src={img3} className={classes.imgFluid} alt="" />
              </div>
              <p className={classes.topReasonsNumberText}>
                <Trans>{t("main_four__item3")}</Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopReasons;
