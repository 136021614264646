import React from "react";
import classes from "./TrustedPartner.module.scss";
import icon_01 from "../../../assets/img/StartPage/trusted_01.png";
import icon_02 from "../../../assets/img/StartPage/trusted_02.png";
import icon_03 from "../../../assets/img/StartPage/trusted_03.png";
import icon_04 from "../../../assets/img/StartPage/trusted_04.png";
import Button from "../../GlobalComponents/Button/Button";
import { Trans, useTranslation } from "react-i18next";
const TrustedPartner = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.trustedPartner}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("start_three__title")}
        </div>
        <div className={[classes.text, "text-default"].join(" ")}>
          {t("start_three__subtitle")}
        </div>
        <div className={classes.cards}>
          <div className={classes.card}>
            <img
              className={[classes.cardIcon, "no-select"].join(" ")}
              src={icon_01}
              alt=""
            />
            <div className={[classes.cardText, "text-default"].join(" ")}>
              <Trans>{t("start_three__item1")}</Trans>
            </div>
          </div>
          <div className={classes.card}>
            <img
              className={[classes.cardIcon, "no-select"].join(" ")}
              src={icon_02}
              alt=""
            />
            <div className={[classes.cardText, "text-default"].join(" ")}>
              <Trans>{t("start_three__item2")}</Trans>
            </div>
          </div>
          <div className={classes.card}>
            <img
              className={[classes.cardIcon, "no-select"].join(" ")}
              src={icon_03}
              alt=""
            />
            <div className={[classes.cardText, "text-default"].join(" ")}>
              <Trans>{t("start_three__item3")}</Trans>
            </div>
          </div>
          <div className={classes.card}>
            <img
              className={[classes.cardIcon, "no-select"].join(" ")}
              src={icon_04}
              alt=""
            />
            <div className={[classes.cardText, "text-default"].join(" ")}>
              <Trans>{t("start_three__item4")}</Trans>
            </div>
          </div>
        </div>
        <div className={classes.btn}>
          <Button>{t("btn_start_now")}</Button>
        </div>
      </div>
    </div>
  );
};

export default TrustedPartner;
