import React, { useRef, useEffect } from "react";
import classes from "./GetStarted.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import icon_01 from "../../../assets/img/TradingSpacePage/getstarted_icon_01.png";
import icon_02 from "../../../assets/img/TradingSpacePage/getstarted_icon_02.png";
import icon_03 from "../../../assets/img/TradingSpacePage/getstarted_icon_03.png";
import icon_04 from "../../../assets/img/TradingSpacePage/getstarted_icon_04.png";
import icon_05 from "../../../assets/img/TradingSpacePage/getstarted_icon_05.png";
import icon_06 from "../../../assets/img/TradingSpacePage/getstarted_icon_06.png";
import light_01 from "../../../assets/img/TradingSpacePage/getstarted_light_01.png";
import light_02 from "../../../assets/img/TradingSpacePage/getstarted_light_02.png";
import light_03 from "../../../assets/img/TradingSpacePage/getstarted_light_03.png";
import light_04 from "../../../assets/img/TradingSpacePage/getstarted_light_04.png";
import light_05 from "../../../assets/img/TradingSpacePage/getstarted_light_05.png";
import light_06 from "../../../assets/img/TradingSpacePage/getstarted_light_06.png";
import { useTranslation } from "react-i18next";
const QuickFacts = () => {
  const { t } = useTranslation();
  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    const element = ref.current;
    if (document.documentElement.clientWidth > 1220) {
      gsap.timeline().fromTo(
        element.querySelector(`#plate_02`),
        {
          x: -600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_01`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_03`),
        {
          x: 600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_02`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_04`),
        {
          x: -600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_03`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_05`),
        {
          x: 600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_04`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`#plate_06`),
        {
          x: -600,
        },
        {
          x: 0,
          scrollTrigger: {
            trigger: element.querySelector(`#plate_05`),
            start: "top bottom",
            end: "top center",
            scrub: 5,
            id: "scrub",
          },
        }
      );
    }
  }, []);
  return (
    <div>
      <div className={classes.quickFacts} ref={ref}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("trading_second__title")}
        </div>
        <div className={classes.content}>
          <div
            id="plate_01"
            className={[
              classes.plate,
              classes.plateRight,
              classes.plate_01,
            ].join(" ")}
          >
            <img
              className={[classes.light, classes.light_01].join(" ")}
              src={light_01}
              alt=""
            />
            <img
              className={[classes.icon, classes.icon_01].join(" ")}
              src={icon_01}
              alt=""
            />
            <div className={classes.num}>01</div>
            <div className={classes.text}>
              <div className={classes.first}>
                {t("trading_second_item1__title")}
              </div>
              <div className={classes.second}>
                {t("trading_second_item1__subtitle")}
              </div>
            </div>
          </div>
          <div
            id="plate_02"
            className={[
              classes.plate,
              classes.plateLeft,
              classes.plate_02,
            ].join(" ")}
          >
            <img
              className={[classes.light, classes.light_02].join(" ")}
              src={light_02}
              alt=""
            />
            <img
              className={[classes.icon, classes.icon_02].join(" ")}
              src={icon_02}
              alt=""
            />
            <div className={classes.text}>
              <div className={classes.first}>
                {t("trading_second_item2__title")}
              </div>
              <div className={classes.second}>
                {t("trading_second_item2__subtitle")}
              </div>
            </div>
            <div className={classes.num}>02</div>
          </div>
          <div
            id="plate_03"
            className={[
              classes.plate,
              classes.plateRight,
              classes.plate_03,
            ].join(" ")}
          >
            <img
              className={[classes.light, classes.light_03].join(" ")}
              src={light_03}
              alt=""
            />
            <img
              className={[classes.icon, classes.icon_03].join(" ")}
              src={icon_03}
              alt=""
            />
            <div className={classes.num}>03</div>
            <div className={classes.text}>
              <div className={classes.first}>
                {" "}
                {t("trading_second_item3__title")}
              </div>
              <div className={classes.second}>
                {t("trading_second_item3__subtitle")}
              </div>
            </div>
          </div>
          <div
            id="plate_04"
            className={[
              classes.plate,
              classes.plateLeft,
              classes.plate_04,
            ].join(" ")}
          >
            <img
              className={[classes.light, classes.light_04].join(" ")}
              src={light_04}
              alt=""
            />
            <img
              className={[classes.icon, classes.icon_04].join(" ")}
              src={icon_04}
              alt=""
            />
            <div className={classes.text}>
              <div className={classes.first}>
                {" "}
                {t("trading_second_item4__title")}
              </div>
              <div className={classes.second}>
                {t("trading_second_item4__subtitle")}
              </div>
            </div>
            <div className={classes.num}>04</div>
          </div>
          <div
            id="plate_05"
            className={[
              classes.plate,
              classes.plateRight,
              classes.plate_05,
            ].join(" ")}
          >
            <img
              className={[classes.light, classes.light_05].join(" ")}
              src={light_05}
              alt=""
            />
            <img
              className={[classes.icon, classes.icon_05].join(" ")}
              src={icon_05}
              alt=""
            />
            <div className={classes.num}>05</div>
            <div className={classes.text}>
              <div className={classes.first}>
                {" "}
                {t("trading_second_item5__title")}
              </div>
              <div className={classes.second}>
                {t("trading_second_item5__subtitle")}
              </div>
            </div>
          </div>
          <div
            id="plate_06"
            className={[
              classes.plate,
              classes.plateLeft,
              classes.plate_06,
            ].join(" ")}
          >
            <img
              className={[classes.light, classes.light_06].join(" ")}
              src={light_06}
              alt=""
            />
            <img
              className={[classes.icon, classes.icon_06].join(" ")}
              src={icon_06}
              alt=""
            />
            <div className={classes.text}>
              <div className={classes.first}>
                {" "}
                {t("trading_second_item6__title")}
              </div>
              <div className={classes.second}>
                {t("trading_second_item6__subtitle")}
              </div>
            </div>
            <div className={classes.num}>06</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickFacts;
