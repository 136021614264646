import React from "react";
import classes from "./Spoiler.module.scss";
import { useTranslation } from "react-i18next";

const Spoiler = (props) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        props.active
          ? [classes.spoiler, classes.active].join(" ")
          : classes.spoiler
      }
      onClick={props.clickEvent}
    >
      <div className={classes.spoilerTitle}>{t(props.title)}</div>
      {typeof props.text === "string" ? (
        <div className={classes.spoilerContent}>{t(props.text)}</div>
      ) : (
        props.text.map((text, index) =>
          typeof text === "string" ? (
            <div className={classes.spoilerContent} key={index}>
              {text}
            </div>
          ) : (
            text.map((item, index) => (
              <div
                className={`${classes.spoilerContent} ${
                  classes.spoilerContentList
                } ${index === 0 && classes.listMargin}`}
                key={index}
              >
                {item}
              </div>
            ))
          )
        )
      )}
    </div>
  );
};

export default Spoiler;
