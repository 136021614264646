let eng_tabs = [
    {
        id: 1,
        title: "What is FOREX?",
        text: [
            "The current image of the international currency market was formed after, in March 1973, the EU member countries abandoned the fixed exchange rate system.",
            "One of the features of the FOREX market is that being an interbank market, it does not have a specific trading place, such as stocks or currency futures.",
            "Trading takes place by phone and through the terminals of electronic brokerage systems between hundreds of banks worldwide throughout the week.",
            "Considering that the majority of transactions in the interbank foreign exchange market FOREX are in the amount of 5-10 million dollars, not everyone can, with delivery, purchase such an amount of currency.",
            "Therefore, the main participants in such a market are commercial banks, central banks, companies engaged in foreign trade operations, large investment companies, and funds. However, due to the possibility of using the principle of margin trading (or leverage) in the FOREX market, individuals and small companies can also work in this market.",
            "Leverage allows them to make transactions for amounts much greater than their funds, and liquid coverage (deposit) is used as collateral.",
            "Such participants in the international currency market perform arbitrage transactions with foreign currency or with derivative instruments, the underlying asset of which is the currency: they carry out purchase and sale transactions to extract arbitrage profit by changing the exchange rate.",
            "At the same time, there is no real delivery of the purchased currency, and the client simply closes his position on the settlement date (makes the opposite transaction). We are talking about transactions with “shoulder,” ten times greater than the deposit size.",
            "Due to leverage, such clients can hedge (insure) their currency risks. and the client simply closes his position on the settlement date (makes the opposite trade). We are talking about transactions with “shoulder,” ten times greater than the deposit size.",
            "Due to leverage, such clients can hedge (insure) their currency risks."
        ],
        active: false,
    },
    {
        id: 2,
        title: "Who provides services in the FOREX currency market?",
        text: [
            "The main groups of organizations offering FOREX services:",
            [
                "Banks holding a general (currency) license;",
                "Licensed Forex Dealers;",
                "Offshore dealing centers.",
            ],
        ],
        active: false,
    },
    {
        id: 3,
        title:
            "Why does VTB Forex not provide clients with the opportunity to open locked positions?",
        text: [
            "Locking a position usually means the ability to open a new position that matches the one already opened in terms of a pair of currencies and size but is opposite in sign to it. Let us immediately note that this procedure is unknown to the FOREX interbank market, where all world banks operate. Let's explain why.",
            "Having bought, for example, 1 million (10 lots) euros against US dollars (EUR/USD) with a specific settlement date at a certain rate, a trader opens a long position of 1 million euros and a short position of 1 million × the transaction rate — at US dollar.",
            "Suppose a trader sells 1 million EUR/USD with the same settlement date at a different rate. In that case, the position in euro will become equal to zero (1 million — 1 million = 0), and in US dollars, it will be equal to the result of the arbitrage operation (transaction rate 1 – transaction rate 2) * 1 million), that is, the trader will not have any position in the base currency (in this case, in the euro): it will be closed.",
            "It will happen exactly this way and not otherwise: the rules of international and banking accounting (accounting and trading) of conversion operations speak about this.",
            "When locking positions, everything happens differently.",
            [
                "First, by making two opposite transactions simultaneously, the trader immediately incurs a loss in the amount of the spread (the difference between the buying and selling rates). At the same time, the trader does not have a real open currency position in the Forex market, so there is no opportunity to work off these lost funds.",
                "Secondly, having virtually opened two positions, the trader pays when changing the value date for their transfer to a new date.",
                "Thirdly, after carrying out two or three transactions in the future, a trader, as a rule, begins to get confused about their positions and financial results, which makes it difficult to assess actions in the market. If you carefully analyze this, you can see that such practice benefits only the “miracle broker” through which the trader works (the trader pays him all the time: spread, position transfer, etc.).",
            ],
            "For these reasons, we do not support such a negative practice because we respect our Clients and value them.",
        ],
        active: false,
    },
    {
        id: 4,
        title: "How to close a position in the Forex market?",
        text: [
            "The position is closed by making a deal in the same pair of currencies for the same amount as the position is opened for, but with the opposite sign. For example, if a trader has a long position (purchase) on EUR/USD with a volume of 100,000 (1 lot), the trader needs to sell 100,000 EUR/USD to close it.",
            "If a trader has a short position (sell) of the same volume in the same pair, it is necessary to buy 100,000 EUR/USD to close it.",
            "The principle of operation of the Forex market is completely subject to the laws of arithmetic:",
            "100,000–100,000=0, or -100,000+100,000=0, or 200,000–100,000–100,000=0.",
        ],
        active: false,
    },
    {
        id: 5,
        title: "How to place Immediate Execution Orders on the trading platform?",
        text: [
            "You can call the order creation dialog box in various ways:",
            [
                "In the Market Watch window, select the required instrument and execute the New Order command from the context menu.",
                'Press the hot key "F9". In this case, terminal settings will substitute the symbol in the order window.',
                'Execute the "New Order" command in the "Tools" menu or on the "Standard" toolbar.',
            ],
            'After performing one of the above actions, an order placement window will open corresponding to the type of execution for the selected instrument: "Immediate execution."',
            'In this mode, a market order is executed at the current market price. The terminal automatically inserts the current prices into the order. When opening a position in this mode, in the "Symbol" field of the "Order" window, select the financial instrument for which you want to make a deal.',
            "In the order parameters block, fill in the following fields:",
            [
                "Volume — order volume in lots (1 lot = 100,000 base currency units).",
                "Stop Loss — Stop Loss level in prices or the number of points from the price specified in the order, depending on the terminal settings. If you leave a zero value in the field, this type of order will not be attached.",
                "Take Profit — Take Profit level in prices or the number of points from the price specified in the order, depending on the terminal settings. If you leave a zero value in the field, this type of order will not be attached.",
            ],
            'After all the necessary data are indicated, you must click on the "Sell" or "Buy" button. In this case, an order is sent to the dealer to open a short or long position. To completely close a position, executing the "Close position" command in its context menu on the "Trade" tab is necessary. The window for closing a position is similar to the window for opening it, except for the "Close" button.',
        ],
        active: false,
    },
    {
        id: 6,
        title:
            "What types of pending orders are there, and what is the difference between the specifics of their execution?",
        text: [
            "There are two orders in FOREX: stop loss and take profit.",
            "The type of the order does not depend on the action it performs with the trader's currency position. To determine the type of order, it does not matter whether it is an order to open a position, close, increase or decrease. And the order type depends on the price level of this order concerning the current price level in the market:",
            [
                "An order to BUY at a price HIGHER than the current price level in the market is a STOP-LOSS order (in MetaTrader 5 — Buy Stop)",
                "An order to BUY at a price LOWER than the current price level in the market is a TAKE PROFIT type order (in MetaTrader5 — Buy Limit)",
                "An order to SELL at a price LOWER than the current price level on the market is a STOP-LOSS order (in MetaTrader 5 — Sell Stop)",
                "An order to SELL at a price HIGHER than the current price level on the market is a TAKE PROFIT type order (in MetaTrader 5 — Sell Limit)",
            ],
            "Depending on the type (stop-loss or take-profit) of the order, the execution of orders on FOREX also differs.",
            "A take-profit order is executed when the market reaches the order price in such a way that the order price matches the forex dealer's quote.",
            "Stop-loss orders are executed at a price next to that specified in the order. Thus, a stop-loss order can be executed both at a price specified in the order (if the market reached this level and slowed down on it), and at a price worse than that indicated in the order (if the market, having reached the order price, continued your move forward).",
            "At the same time, the deviation value can be either 1–3 points from the order price (the most common situation with a smooth market movement) or differ by a large amount (a rare situation, typical for a sharp market movement at the time of the release of important economic news).",
        ],
        active: false,
    },
    {
        id: 7,
        title: "What is the MINIMUM deposit you can start trading on Forex with?",
        text: "The minimum amount of funds on deposit (Margin) required to conduct transactions with a particular currency pair is calculated as the ratio of the transaction volume to the maximum leverage, in dollar or euro equivalent, depending on the Active account currency.",
        active: false,
    },
]
let ru_tabs = [
    {
        id: 1,
        title: "Что такое FOREX?",
        text: [
            "Нынешний облик международного валютного рынка сформировался после того, как в марте 1973 г. страны-члены ЕС отказались от системы фиксированных обменных курсов.",
            "Одной из особенностей рынка FOREX является то, что, будучи межбанковским рынком, он не имеет определенного торгового места, например, акции или валютные фьючерсы. ",
            "Торговля происходит по телефону и через терминалы электронных брокерских систем между сотнями банков по всему миру в течение недели.",
            "Учитывая, что большинство сделок на межбанковском валютном рынке FOREX заключаются на сумму 5-10 миллионов долларов, не каждый может с доставкой приобрести такое количество валюты. ",
            "Поэтому основными участниками такого рынка являются коммерческие банки, центральные банки, компании, осуществляющие внешнеторговые операции, крупные инвестиционные компании и фонды. Однако, благодаря возможности использования принципа маржинальной торговли (или кредитного плеча) на рынке FOREX, на этом рынке могут работать и частные лица, и небольшие компании. ",
            "Кредитное плечо позволяет им совершать сделки на суммы, намного превышающие их средства, а ликвидное покрытие (депозит) используется в качестве залога. Такие участники международного валютного рынка совершают арбитражные операции с иностранной валютой или с производными инструментами, базовым активом которых является валюта: осуществляют сделки купли-продажи для извлечения арбитражной прибыли путем изменения обменного курса. ",
            "При этом реальной поставки купленной валюты не происходит, а клиент просто закрывает свою позицию в дату расчета (совершает обратную сделку). Речь идет о сделках с «плечом», в десять раз превышающим размер депозита. ",
            "Благодаря кредитному плечу такие клиенты могут хеджировать (страховать) свои валютные риски. а клиент просто закрывает свою позицию в расчетную дату (совершает обратную сделку). Речь идет о сделках с «плечом», в десять раз превышающим размер депозита.",
            "Благодаря кредитному плечу такие клиенты могут хеджировать (страховать) свои валютные риски. и клиент просто закрывает свою позицию в расчетную дату (совершает обратную сделку). Речь идет о сделках с «плечом», в десять раз превышающим размер депозита. ",
            "Благодаря кредитному плечу такие клиенты могут хеджировать (страховать) свои валютные риски."
        ],
        active: false,
    },
    {
        id: 2,
        title: "Кто оказывает услуги на валютном рынке FOREX?",
        text: [
            "Основные группы организаций, предлагающих услуги FOREX:",
            [
                "Банки, имеющие генеральную (валютную) лицензию;",
                "Лицензированные форекс-дилеры;",
                "Оффшорные дилинговые центры.",
            ],
        ],
        active: false,
    },
    {
        id: 3,
        title:
            "Почему ВТБ Форекс не предоставляет клиентам возможность открывать локированные позиции?",
        text: [
            "Под локированием позиции обычно понимается возможность открытия новой позиции, соответствующей уже открытой по паре валют и размеру, но противоположной ей по знаку. Сразу отметим, что данная процедура неизвестна межбанковскому рынку FOREX, где работают все мировые банки. Давайте объясним, почему.",
            "Купив, например, 1 миллион (10 лотов) евро за доллары США (EUR/USD) с определенной датой расчетов по определенному курсу, трейдер открывает длинную позицию на 1 миллион евро и короткую позицию на 1 миллион × курс операции — в долларах США. ",
            "Предположим, трейдер продает 1 миллион EUR/USD с той же датой расчетов по другому курсу. В этом случае позиция в евро станет равной нулю (1 миллион — 1 миллион = 0), а в долларах США — результату арбитражной операции (курс сделки 1 — курс сделки 2) * 1 млн), то есть у трейдера не будет позиции в базовой валюте (в данном случае в евро): она будет закрыта. ",
            "Произойдет именно так, а не иначе: об этом говорят правила международного и банковского учета (учетно-торгового) конверсионных операций.",
            "При локировании позиций все происходит иначе. ",
            [
                "Во-первых, совершая две противоположные сделки одновременно, трейдер сразу же несет убыток в размере спреда (разницы между курсами покупки и продажи). При этом у трейдера нет реальной открытой валютной позиции на рынке Форекс, поэтому нет возможности отработать эти потерянные средства. ",
                "Во-вторых, виртуально открыв две позиции, трейдер платит при изменении даты валютирования за их перенос на новую дату. ",
                "В-третьих, после проведения двух-трех сделок в будущем трейдер, как правило, начинает путаться в своих позициях и финансовых результатах, что затрудняет оценку действий на рынке. Если внимательно это проанализировать, то можно увидеть, что такая практика приносит пользу только тому «чудо-брокеру», через которого работает трейдер (трейдер ему все время платит: спред, перенос позиции и т.д.).",
            ],
            "По этим причинам мы не поддерживаем такую ​​негативную практику, потому что мы уважаем наших Клиентов и ценим их.",
        ],
        active: false,
    },
    {
        id: 4,
        title: "Как закрыть позицию на рынке Форекс?",
        text: [
            "Позиция закрывается путем совершения сделки в той же паре валют на ту же сумму, на которую открывается позиция, но с обратным знаком. Например, если у трейдера есть длинная позиция (покупка) по EUR/USD объемом 100 000 (1 лот), трейдеру необходимо продать 100 000 EUR/USD, чтобы закрыть ее.",
            "Если у трейдера есть короткая позиция (продажа) того же объема в той же паре, для ее закрытия необходимо купить 100 000 EUR/USD.",
            "Принцип работы рынка Форекс полностью подчиняется законам арифметики:",
            "100 000–100 000=0, или -100 000+100 000=0, или 200 000–100 000–100 000=0.",
        ],
        active: false,
    },
    {
        id: 5,
        title: "Как размещать ордера немедленного исполнения на торговой платформе?",
        text: [
            "диалоговое окно создания ордера можно различными способами:",
            [
                "В окне «Обзор рынка» выбрать нужный инструмент и выполнить команду «Новый ордер» из контекстного меню.",
                'Нажмите горячую клавишу «F9». В этом случае настройки терминала подставят символ в окне ордера.',
                'Выполните команду «Новый заказ» в меню «Инструменты» или на панели инструментов «Стандартная».',
            ],
            'После выполнения одного из вышеперечисленных действий откроется окно выставления ордера, соответствующее типу исполнения по выбранному инструменту: «Немедленное исполнение». ',
            'В этом режиме рыночный ордер исполняется по текущей рыночной цене. Терминал автоматически подставляет текущие цены в ордер. При открытии позиции в этом режиме в поле «Символ» окна «Заявка» выберите финансовый инструмент, по которому вы хотите совершить сделку.',
            "В блоке параметров ордера заполните следующие поля:",
            [
                "Объем — объем ордера в лотах (1 лот = 100 000 единиц базовой валюты).",
                "Stop Loss — уровень Stop Loss в ценах или количество пунктов от цены, указанной в ордере, в зависимости от настроек терминала. Если вы оставите нулевое значение в поле, этот тип ордера не будет прикреплен.",
                "Take Profit — уровень Take Profit в ценах или количество пунктов от цены, указанной в ордере, в зависимости от настроек терминала. Если вы оставите нулевое значение в поле, этот тип ордера не будет прикреплен.",
            ],
            'После того, как все необходимые данные будут указаны, необходимо нажать на кнопку «Продать» или «Купить». В этом случае дилеру отправляется приказ на открытие короткой или длинной позиции. Для полного закрытия позиции необходимо выполнить команду "Закрыть позицию" в ее контекстном меню на вкладке "Торговля". Окно закрытия позиции аналогично окну ее открытия, за исключением кнопки «Закрыть».',
        ],
        active: false,
    },
    {
        id: 6,
        title:
            "Какие виды отложенных ордеров бывают, и в чем разница между спецификой их исполнения?",
        text: [
            "На FOREX есть два ордера: стоп-лосс и тейк-профит.",
            "Тип ордера не зависит от того, какое действие он производит с валютной позицией трейдера. Для определения типа ордера не имеет значения, это ордер на открытие позиции, закрытие, увеличение или уменьшение. А тип ордера зависит от ценового уровня этого ордера относительно текущего уровня цен на рынке:",
            [
                "Ордер на ПОКУПКУ по цене ВЫШЕ, чем текущий уровень цен на рынке, является СТОП-ЛОСС-ордером (в МетаТрейдер 5 — Buy ​​Stop). )",
                "Ордер на ПОКУПКУ по цене НИЖЕ текущего уровня цен на рынке является ордером типа TAKE PROFIT (в MetaTrader5 — Buy ​​Limit)",
                "Ордер на ПРОДАЖУ по цене НИЖЕ текущего уровня цен на рынке является СТОП- LOSS ордер (в MetaTrader 5 — Sell Stop)",
                "Ордер на SELL по цене ВЫШЕ текущего уровня цен на рынке является TAKE PROFIT ордером (в MetaTrader 5 — Sell Limit)",
            ],
            "В зависимости от типа (stop-loss или take -прибыль) ордера, исполнение ордеров на FOREX тоже отличается.",
            "Ордер тейк-профит исполняется, когда рынок достигает цены ордера таким образом, что цена ордера соответствует котировке форекс-дилера.",
            "Стоп-лосс ордера исполняются по цене, следующей за указанной в ордере. Таким образом, ордер стоп-лосс может быть исполнен как по цене, указанной в ордере (если рынок достиг этого уровня и замедлился на нем), так и по цене хуже указанной в ордере (если рынок, достигнув цена ордера, продолжили движение вперед). ",
            "При этом величина отклонения может составлять как 1–3 пункта от цены ордера (наиболее распространенная ситуация при плавном движении рынка), так и отличаться на большую величину (редкая ситуация, характерная для резкого движения рынка в момент выхода важных экономических новостей).",
        ],
        active: false,
    },
    {
        id: 7,
        title: "С какого МИНИМАЛЬНОГО депозита вы можете начать торговать на Forex?",
        text: "Минимальная сумма средств на депозите (Маржа), необходимая для проведения сделок с конкретной валютной парой, рассчитывается как отношение объема сделки к максимальному кредитному плечу, в долларовом или евро эквиваленте, в зависимости от валюты Активного счета.",
        active: false,
    },
]
let pl_tabs = [
    {
        id: 1,
        title: "Co to jest FOREX?",
        text: [
            "Obecny kształt międzynarodowego rynku walutowego ukształtował się po porzuceniu przez państwa członkowskie UE systemu stałych kursów walutowych w marcu 1973 roku.",
            "Jedną z cech rynku FOREX jest to, że jako rynek międzybankowy nie ma on określonego miejsca obrotu, jak np. akcje czy kontrakty terminowe na waluty. ",
            "Handel odbywa się za pośrednictwem telefonu i elektronicznych terminali maklerskich pomiędzy setkami banków na całym świecie w ciągu tygodnia.",
            "Biorąc pod uwagę, że większość transakcji na międzybankowym rynku FOREX opiewa na 5-10 mln dolarów, nie każdy może kupić tyle waluty z dostawą. ",
            "Dlatego głównymi graczami na tym rynku są banki komercyjne, banki centralne, firmy zajmujące się transakcjami handlu zagranicznego, duże firmy inwestycyjne i fundusze.Jednak ze względu na możliwość korzystania z transakcji zabezpieczających(lub dźwigni finansowej) na FOREX, na rynku tym mogą działać zarówno osoby prywatne, jak i małe firmy.",
            "Dźwignia finansowa umożliwia im obrót kwotami znacznie przekraczającymi ich fundusze, podczas gdy płynne pokrycie (depozyt) służy jako zabezpieczenie. Tacy uczestnicy międzynarodowego rynku walutowego dokonują arbitrażu walutowego lub instrumentów pochodnych z walutą jako aktywem bazowym: kupują i sprzedają w celu osiągnięcia zysków arbitrażowych poprzez zmianę kursu walutowego. ",
            "Nie dochodzi do realnej dostawy zakupionej waluty, a klient po prostu zamyka swoją pozycję w dniu rozliczenia (transakcja odwrotna). Mówimy tu o transakcjach z dźwignią wynoszącą dziesięciokrotność kwoty depozytu. ",
            "Dzięki dźwigni finansowej tacy klienci mogą zabezpieczyć (ubezpieczyć) swoje ryzyko walutowe. a klient po prostu zamyka swoją pozycję w dniu rozliczenia (wykonuje transakcję odwrotną). Mówimy o transakcjach z \"dźwignią\" dziesięciokrotnie większą od wielkości depozytu. ",
            "Dzięki dźwigni finansowej tacy klienci mogą zabezpieczyć (ubezpieczyć) swoje ryzyko walutowe. a klient po prostu zamyka swoją pozycję w dniu rozliczenia (wykonuje transakcję odwrotną). Mówimy o transakcjach z \"dźwignią\" dziesięciokrotnie większą od wielkości depozytu. ",
            "Dzięki dźwigni finansowej tacy klienci mogą zabezpieczyć (ubezpieczyć) swoje ryzyko walutowe."
        ],
        active: false,
    },
    {
        id: 2,
        title: "Kto świadczy usługi na rynku FOREX?",
        text: [
            "Główne grupy organizacji oferujących usługi FOREX:",
            [
                "Banki posiadające licencję ogólną (dewizową);",
                "Licencjonowani dealerzy forex;",
                "Centra dealingowe typu offshore.",
            ],
        ],
        active: false,
    },
    {
        id: 3,
        title:
            "Dlaczego VTB Forex nie oferuje klientom możliwości otwierania zablokowanych pozycji?",
        text: [
            "Blokowanie pozycji zazwyczaj odnosi się do możliwości otwarcia nowej pozycji odpowiadającej parze walutowej i wielkości już otwartej, ale przeciwnej do niej w znaku. Od razu należy zaznaczyć, że procedura ta jest nieznana na międzybankowym rynku FOREX, na którym działają wszystkie banki świata. Wyjaśnijmy, dlaczego.",
            "Kupiwszy, na przykład, 1 milion (10 lotów) euro za dolary amerykańskie (EUR/USD) z określoną datą rozliczenia po określonym kursie, inwestor otwiera długą pozycję na 1 milion euro i krótką pozycję na 1 milion × kurs transakcyjny - w dolarach amerykańskich. ",
            "Załóżmy, że inwestor sprzedaje 1 mln EUR/USD w tym samym dniu rozliczenia po innym kursie. W tym przypadku pozycja w EUR będzie równa zeru (1 mln - 1 mln = 0), a pozycja w USD będzie równa wynikowi operacji arbitrażowej (kurs transakcji 1 - kurs transakcji 2) * 1 mln), czyli inwestor nie będzie miał pozycji w walucie bazowej (w tym przypadku EUR): zostanie ona zamknięta. ",
            "Stanie się dokładnie tak, a nie inaczej: mówią o tym zasady rachunkowości międzynarodowej i bankowej (księgowej i handlowej) dla transakcji przeliczeniowych.",
            "Nie dotyczy to pozycji blokujących. ",
            [
                "Po pierwsze, dokonując dwóch przeciwstawnych transakcji w tym samym czasie, inwestor natychmiast ponosi stratę w wysokości spreadu (różnica między kursami kupna i sprzedaży). W tym przypadku inwestor nie ma realnie otwartej pozycji walutowej na rynku Forex, więc nie ma możliwości odpracowania tych utraconych środków. ",
                "Po drugie, mając wirtualnie otwarte dwie pozycje, trader płaci za ich przeniesienie na nową datę waluty. ",
                "Po trzecie, po przeprowadzeniu dwóch lub trzech transakcji w przyszłości trader zazwyczaj zaczyna mylić swoje pozycje i wyniki finansowe, co utrudnia ocenę działań na rynku. Jeśli przeanalizujesz to dokładnie, zobaczysz, że taka praktyka jest korzystna tylko dla tego \"cudownego brokera\", przez którego pracuje trader (trader płaci mu cały czas: spread, rolowanie pozycji itp.)",
            ],
            "Z tych powodów nie popieramy takich negatywnych praktyk, ponieważ szanujemy i cenimy naszych Klientów.",
        ],
        active: false,
    },
    {
        id: 4,
        title: "Jak zamknąć pozycję na rynku Forex?",
        text: [
            "Pozycja jest zamykana poprzez wykonanie transakcji w tej samej parze walutowej dla tej samej kwoty, dla której pozycja została otwarta, ale z przeciwnym znakiem. Na przykład, jeśli inwestor ma długą pozycję (buy) w EUR/USD o wartości 100.000 (1 lot), musi sprzedać 100.000 EUR/USD, aby ją zamknąć.",
            "Jeśli inwestorzy posiadają krótką pozycję (sell) o tym samym wolumenie w tej samej parze, muszą kupić 100 000 EUR/USD, aby ją zamknąć.",
            "Zasada działania rynku Forex podlega prawom arytmetyki:",
            "100 000-100 000=0, lub -100 000+100 000=0, lub 200 000-100 000=0.",
        ],
        active: false,
    },
    {
        id: 5,
        title: "Jak mogę składać zlecenia natychmiastowej realizacji na platformie handlowej?",
        text: [
            "okno dialogowe tworzenia zlecenia na różne sposoby:",
            [
                "Zaznacz wybrany instrument w oknie Market Watch i wykonaj polecenie \"Nowe zlecenie\" z menu kontekstowego.",
                'Naciśnij klawisz skrótu "F9". W tym przypadku ustawienia terminalu zastąpią symbol w oknie zlecenia.',
                'Wykonaj polecenie "Nowe zamówienie" z menu "Narzędzia" lub z paska narzędzi "Standard".',
            ],
            'Po wykonaniu jednej z powyższych czynności otworzy się okno składania zleceń odpowiadające rodzajowi wykonania dla wybranego instrumentu: "Natychmiastowe wykonanie". ',
            'W tym trybie zlecenie rynkowe jest realizowane po aktualnej cenie rynkowej. Terminal automatycznie zastępuje w zamówieniu aktualne ceny. Kiedy otwierasz pozycję w tym trybie, wybierz instrument finansowy, którym chcesz handlować w polu "Symbol" okna "Zlecenie".',
            "W bloku Parametry zamówienia należy wypełnić następujące pola:",
            [
                "⦁	Wolumen - wielkość zlecenia w lotach (1 lot = 100 000 jednostek waluty bazowej).",
                "⦁	Stop Loss - poziom Stop Loss w cenach lub liczbie pipsów od ceny określonej w zleceniu, w zależności od ustawień terminala. Pozostawienie w polu wartości zerowej spowoduje, że ten typ zamówienia nie zostanie dołączony.",
                "⦁	Take Profit - poziom Take Profit w cenach lub ilość pipsów od ceny określonej w zleceniu w zależności od ustawień terminala. Pozostawienie zerowej wartości w polu spowoduje, że ten typ zamówienia nie zostanie dołączony.",
            ],
            'Po wprowadzeniu wszystkich niezbędnych danych należy nacisnąć przycisk "Sprzedaj" lub "Kup". W tym przypadku zlecenie otwarcia krótkiej lub długiej pozycji jest wysyłane do dealera. Aby całkowicie zamknąć pozycję, wykonaj polecenie "Zamknij pozycję" w jej menu kontekstowym w zakładce "Handel". Okno zamykania pozycji jest podobne do okna jej otwierania, z wyjątkiem przycisku "Zamknij".',
        ],
        active: false,
    },
    {
        id: 6,
        title:
            "Jakie są rodzaje zleceń oczekujących i czym różni się specyfika ich realizacji?",
        text: [
            "Na rynku FOREX istnieją dwa zlecenia: stop loss i take profit.",
            "Rodzaj zlecenia nie zależy od tego, jaką czynność wykonuje ono na pozycji walutowej inwestora. Dla określenia rodzaju zlecenia nie ma znaczenia czy jest to zlecenie otwarcia pozycji, jej zamknięcia, zwiększenia czy zmniejszenia. A typ zlecenia zależy od poziomu ceny tego zlecenia w stosunku do aktualnego poziomu cen na rynku:",
            [
                "Zlecenie KUPNA po cenie WYŻSZEJ niż aktualny poziom cen rynkowych jest zleceniem STOP-LOSS (w MetaTrader 5, Buy Stop). )",
                "Zlecenie KUPNA po cenie MNIEJSZEJ niż aktualny poziom cen rynkowych jest zleceniem TAKE PROFIT (w MetaTrader 5: Buy Limit).",
                "Zlecenie SPRZEDAŻY po cenie MNIEJSZEJ niż aktualny poziom cen na rynku to zlecenie STOP - LOSS (w MetaTrader 5 - Sell Stop)",
                "Zlecenie SELL po cenie wyższej niż aktualny poziom cen na rynku jest zleceniem TAKE PROFIT (w MetaTrader 5 - Sell Limit)",
            ],
            "W zależności od rodzaju (stop-loss lub take - profit) zlecenia, różna jest również realizacja zleceń na rynku FOREX.",
            "Zlecenia Take Profit są realizowane, gdy rynek osiągnie cenę zlecenia, tak aby cena zlecenia odpowiadała kwotowaniu dealera forex.",
            "Zlecenia Stop-loss są realizowane po cenie następującej po tej określonej w zleceniu. Zatem zlecenie stop-loss może zostać zrealizowane zarówno po cenie określonej w zleceniu (jeśli rynek osiągnął ten poziom i na nim wyhamował), jak i po cenie gorszej niż określona w zleceniu (jeśli rynek, po osiągnięciu ceny określonej w zleceniu, kontynuuje ruch do przodu). ",
            "Odchylenie może wynosić od 1 do 3 punktów od ceny zlecenia (najczęstsza sytuacja podczas płynnego ruchu rynku) oraz może różnić się o większą wartość (rzadka sytuacja charakterystyczna dla nagłego ruchu rynku w momencie publikacji ważnych wiadomości gospodarczych).",
        ],
        active: false,
    },
    {
        id: 7,
        title: "Jaki jest MINIMALNY depozyt, od którego można zacząć na Forex?",
        text: "Minimalna kwota środków na depozycie (Margin) wymagana dla transakcji w danej parze walutowej jest obliczana jako stosunek wolumenu transakcji do maksymalnej dźwigni, w ekwiwalencie dolara lub euro, w zależności od waluty Rachunku Aktywnego.",
        active: false,
    },
]
let it_tabs = [
    {
        id: 1,
        title: "Che cos'è il FOREX?",
        text: [
            "L'aspetto attuale del mercato valutario internazionale si è formato dopo che gli Stati membri dell'UE hanno abbandonato il sistema dei tassi di cambio fissi nel marzo 1973.",
            "Una delle caratteristiche del mercato FOREX è che, essendo un mercato interbancario, non ha un luogo di trading specifico, come azioni o futures su valute. ",
            "Il trading avviene per telefono e tramite terminali di sistemi di intermediazione elettronica tra centinaia di banche in tutto il mondo durante la settimana.",
            "Dato che la maggior parte delle transazioni sul mercato interbancario Forex sono concluse per un importo di 5-10 milioni di dollari, non tutti possono acquistare una tale quantità di valuta con la consegna.",
            "Pertanto, i principali partecipanti a tale mercato sono banche commerciali, banche centrali, società che svolgono operazioni di commercio estero, grandi società di investimento e fondi. Tuttavia, grazie alla possibilità di utilizzare il principio del trading a margine (o leva finanziaria) nel mercato FOREX, sia gli individui che le piccole aziende possono operare in questo mercato. ",
            "La leva consente loro di effettuare transazioni per importi molto superiori ai loro fondi e la copertura liquida (deposito) viene utilizzata come garanzia. Tali partecipanti al mercato valutario internazionale effettuano operazioni di arbitraggio con valuta estera o con derivati il cui asset sottostante è la valuta: effettuano operazioni di vendita per estrarre i profitti dell'arbitraggio modificando il tasso di cambio. ",
            "In questo caso, la consegna reale della valuta acquistata non avviene e il cliente chiude semplicemente la sua posizione alla data di regolamento (Effettua una transazione inversa). Stiamo parlando di transazioni con una» spalla  dieci volte la dimensione del deposito.",
            "Grazie alla leva, tali clienti possono coprire (assicurare) i loro rischi in valuta estera. e il cliente chiude semplicemente la sua posizione alla data di regolamento (Effettua una transazione inversa). Stiamo parlando di transazioni con una «spalla» dieci volte la dimensione del deposito.",
            "Grazie alla leva, tali clienti possono coprire (assicurare) i loro rischi in valuta estera. e il cliente chiude semplicemente la sua posizione alla data di regolamento (Effettua una transazione inversa). Stiamo parlando di transazioni con una «spalla» dieci volte la dimensione del deposito. ",
            "Grazie alla leva, tali clienti possono coprire (assicurare) i loro rischi in valuta estera."
        ],
        active: false,
    },
    {
        id: 2,
        title: "Chi fornisce servizi nel mercato dei cambi FOREX?",
        text: [
            "Principali gruppi di organizzazioni che offrono servizi FOREX:",
            [
                "Banche con licenza generale (valuta);",
                "Commercianti di forex autorizzati;",
                "Centri commerciali Offshore.",
            ],
        ],
        active: false,
    },
    {
        id: 3,
        title:
            "Perché VTB Forex non offre ai clienti l'opportunità di aprire posizioni bloccate?",
        text: [
            "Il blocco di una posizione di solito si riferisce alla possibilità di aprire una nuova posizione corrispondente a una coppia di valute e dimensioni già aperte, ma opposta ad essa nel segno. Si noti immediatamente che questa procedura è sconosciuta al mercato interbancario FOREX, dove operano tutte le banche mondiali. Spieghiamo perché.",
            "Acquistando, ad esempio, 1 milione (10 lotti) di euro per dollari USA (EUR/USD) con una data di regolamento specifica per un determinato tasso di cambio, il Trader apre una posizione lunga di 1 milione di euro e una posizione corta di 1 milione × tasso di transazione — in dollari USA. ",
            "Supponiamo che un trader venda 1 milione di EUR/USD con la stessa data di regolamento a un tasso diverso. In questo caso, la posizione in euro diventerà zero (1 milione — 1 milione = 0) e in dollari USA — il risultato dell'operazione di arbitraggio (tasso di Transazione 1 — tasso di Transazione 2) * 1 milione), ovvero il Trader non avrà una posizione nella valuta di base (in questo caso in euro): verrà chiusa. ",
            "Accadrà in questo modo, e non in altro modo: questo è ciò che dicono le regole delle transazioni di conversione internazionali e bancarie (contabilità e commercio).",
            "Quando si bloccano le posizioni, tutto accade in modo diverso. ",
            [
                "In primo luogo, effettuando due operazioni opposte contemporaneamente, il trader subisce immediatamente una perdita nell'importo dello spread (la differenza tra i tassi di acquisto e di vendita). Allo stesso tempo, il Trader non ha una vera posizione di valuta aperta sul mercato Forex, quindi non c'è modo di elaborare questi fondi persi. ",
                "In secondo luogo, aprendo virtualmente due posizioni, il trader paga quando cambia la data di valuta per il loro trasferimento a una nuova data. ",
                "In terzo luogo, dopo aver effettuato due o tre operazioni in futuro, il trader di solito inizia a confondersi nelle sue posizioni e nei risultati finanziari, il che rende difficile valutare le azioni sul mercato. Se lo analizzi attentamente, puoi vedere che questa pratica avvantaggia solo il \"broker miracoloso\" attraverso il quale lavora il Trader (il trader lo paga tutto il tempo: spread, trasferimento di posizione, ecc.).",
            ],
            "Per questi motivi, non supportiamo tali pratiche negative perché rispettiamo e apprezziamo i nostri clienti.",
        ],
        active: false,
    },
    {
        id: 4,
        title: "Come chiudere una posizione nel mercato Forex?",
        text: [
            "La posizione viene chiusa effettuando una transazione nella stessa coppia di valute per lo stesso importo su cui viene aperta la posizione, ma con un segno inverso. Ad esempio, se un Trader ha una posizione lunga (acquisto) su EUR/USD con un volume di 100 000 (1 lotto), il trader deve vendere 100 000 EUR/USD per chiuderlo.",
            "Se un Trader ha una posizione corta (vendita) dello stesso volume nella stessa coppia, è necessario acquistare 100 000 EUR/USD per chiuderla.",
            "Il principio di funzionamento del mercato Forex è completamente soggetto alle leggi dell'aritmetica:",
            "100 000–100 000=0, sia -100 000+100 000=0, sia 200 000–100 000–100 000=0.",
        ],
        active: false,
    },
    {
        id: 5,
        title: "Come effettuare ordini di esecuzione immediata su una piattaforma di trading?",
        text: [
            "la finestra di dialogo Crea ordine può essere utilizzata in vari modi:",
            [
                "Nella finestra «panoramica del mercato», selezionare lo strumento desiderato ed eseguire il comando «Nuovo ordine» dal menu di scelta rapida.",
                'Premere il tasto di scelta rapida «F9». In questo caso, le impostazioni del terminale sostituiranno il simbolo nella finestra dell\'ordine.',
                'Eseguire il comando Nuovo ordine dal menu Strumenti o dalla barra degli strumenti Standard.',
            ],
            'Dopo aver eseguito una delle azioni precedenti, si aprirà una finestra di emissione dell\'ordine corrispondente al tipo di esecuzione dello strumento selezionato: «esecuzione immediata».',
            'In questa modalità, l\'ordine di mercato viene eseguito al prezzo di mercato corrente.Il terminale sostituisce automaticamente i prezzi correnti nell\'ordine. Quando si apre una posizione in questa modalità, nel campo simbolo della finestra offerta, selezionare lo strumento finanziario con cui si desidera effettuare la transazione.',
            "Nel blocco dei parametri dell'ordine, compilare i seguenti campi:",
            [
                "Volume — il volume dell'ordine in lotti (1 lotto = 100 000 unità della valuta di base).",
                "Stop Loss — il livello di Stop Loss nei prezzi o il numero di punti dal prezzo indicato nell'ordine, a seconda delle impostazioni del terminale. Se si lascia un valore nullo nel campo, questo tipo di ordine non verrà allegato.",
                "Take Profit — il livello di Take Profit nei prezzi o il numero di punti dal prezzo specificato nell'ordine, a seconda delle impostazioni del terminale. Se si lascia un valore nullo nel campo, questo tipo di ordine non verrà allegato.",
            ],
            'Dopo aver specificato tutti i dati necessari, è necessario fare clic sul pulsante «Vendi» o «acquista». In questo caso, al rivenditore viene inviato un ordine per aprire una posizione corta o lunga. Per chiudere completamente una posizione, è necessario eseguire il comando «Chiudi posizione» nel relativo menu di scelta rapida nella scheda «trading». La finestra di chiusura di una posizione è simile alla finestra di apertura, ad eccezione del pulsante Chiudi.',
        ],
        active: false,
    },
    {
        id: 6,
        title:
            "Ci sono due ordini sul FOREX: Stop Loss e Take Profit.",
        text: [
            "Ci sono due ordini sul FOREX: Stop Loss e Take Profit.",
            "Il tipo di ordine non dipende dall'azione che produce con la posizione valutaria del trader. Per determinare il tipo di ordine non importa, è un ordine per aprire una posizione, chiudere, aumentare o diminuire. Un tipo di ordine dipende dal livello di prezzo di quell'ordine rispetto all'attuale livello di prezzo sul mercato:",
            [
                "Un ordine di acquisto a un prezzo superiore all'attuale livello di prezzo sul mercato è un ordine Stop-Loss (il Metatrader 5 — Buy Stop).",
                "Un ordine di acquisto al di sotto del livello di prezzo corrente sul mercato è un ordine di tipo TAKE PROFIT (in MetaTrader5 — Buy Limit).",
                "Un ordine di vendita al di sotto del livello di prezzo corrente sul mercato è un ordine Stop-LOSS (in MetaTrader 5 — Sell Stop).",
                "Un ordine di vendita al di sopra del livello di prezzo corrente sul mercato è un ordine TAKE PROFIT (in MetaTrader 5 — Sell Limit).",
            ],
            "A seconda del tipo di ordine (stop-loss o take-Profit), anche l'esecuzione degli ordini FOREX è diversa.",
            "Un ordine take profit viene Eseguito quando il mercato raggiunge il prezzo dell'ordine in modo tale che il prezzo dell'ordine corrisponda alla quotazione del commerciante forex.",
            "Gli ordini stop loss vengono eseguiti al prezzo successivo a quello indicato nell'ordine. Pertanto, un ordine Stop Loss può essere eseguito sia al prezzo indicato nell'ordine (se il mercato ha raggiunto questo livello e ha rallentato su di esso), sia al prezzo peggiore di quello indicato nell'ordine (se il mercato, dopo aver raggiunto il prezzo dell'ordine, ha continuato ad andare avanti). ",
            "Allo stesso tempo, il valore della deviazione può essere come 1-3 punti dal prezzo dell'ordine (la situazione più comune con un movimento regolare del mercato) e differire di un importo maggiore (una situazione rara caratteristica di un forte movimento del mercato al momento del rilascio di importanti notizie economiche).",
        ],
        active: false,
    },
    {
        id: 7,
        title: "Con quale deposito minimo puoi iniziare a fare trading sul Forex?",
        text: "L'importo minimo del deposito (margine) necessario per effettuare transazioni con una particolare coppia di valute viene calcolato come rapporto tra il volume della transazione e la leva massima, in dollari o euro, a seconda della valuta del conto attivo.",
        active: false,
    },
]
let es_tabs = [
    {
        id: 1,
        title: "¿Qué es FOREX?",
        text: [
            "La forma actual del mercado Monetario internacional se formó después de que los Estados miembros de la UE abandonaran el sistema de tipos de cambio fijos en marzo de 1973.",
            "Una de las características del mercado FOREX es que, al ser un mercado interbancario, no tiene un lugar de negociación específico, como acciones o futuros de divisas. ",
            "El comercio se realiza por Teléfono y a través de terminales de sistemas de corretaje electrónico entre cientos de bancos en todo el mundo durante una semana.",
            "Teniendo en cuenta que la mayoría de las transacciones en el mercado interbancario DE divisas Forex se concluyen por un monto de 5-10 millones de dólares, no todos pueden comprar tal cantidad de moneda con la entrega. ",
            "Por lo tanto, los principales participantes en este mercado son los bancos comerciales, los bancos centrales, las empresas de comercio exterior, las grandes empresas de inversión y los fondos. Sin embargo, debido a la posibilidad de utilizar el principio de la negociación de margen (o apalancamiento) en el mercado FOREX, en este mercado pueden trabajar tanto individuos como pequeñas empresas. ",
            "El apalancamiento les permite realizar transacciones por cantidades muy superiores a sus fondos, y la cobertura líquida (depósito) se utiliza como garantía. Dichos participantes en el mercado cambiario internacional realizan operaciones de arbitraje con divisas o derivados cuyo activo subyacente es la moneda: realizan operaciones de venta para obtener ganancias de arbitraje cambiando el tipo de cambio. ",
            "Al mismo tiempo, no se produce una entrega real de la moneda comprada, y el cliente simplemente cierra su posición en la fecha de liquidación (realiza una transacción inversa). Estamos hablando de transacciones con «apalancamiento», diez veces mayor que el Tamaño del depósito. ",
            "Gracias al apalancamiento, estos clientes pueden cubrir (asegurar) sus riesgos cambiarios. y el cliente simplemente cierra su posición en la fecha de liquidación (realiza una transacción inversa). Estamos hablando de transacciones con \"apalancamiento\", diez veces mayor que el Tamaño del depósito. ",
            "Gracias al apalancamiento, estos clientes pueden cubrir (asegurar) sus riesgos cambiarios. y el cliente simplemente cierra su posición en la fecha de liquidación (realiza una transacción inversa). Estamos hablando de transacciones con «apalancamiento», diez veces mayor que el Tamaño del depósito. ",
            "Gracias al apalancamiento, estos clientes pueden cubrir (asegurar) sus riesgos cambiarios."
        ],
        active: false,
    },
    {
        id: 2,
        title: "¿Quién presta servicios en el mercado de divisas FOREX?",
        text: [
            "Principales grupos de organizaciones que ofrecen servicios de FOREX:",
            [
                "Bancos con licencia general (en moneda extranjera) ;",
                "Distribuidores autorizados de Forex;",
                "Centros de negociación Offshore.",
            ],
        ],
        active: false,
    },
    {
        id: 3,
        title:
            "¿Por qué VTB Forex no ofrece a los clientes la oportunidad de abrir posiciones bloqueadas?",
        text: [
            "El bloqueo de una posición generalmente se refiere a la posibilidad de abrir una nueva posición, correspondiente a la ya abierta en un par de monedas y el Tamaño, pero opuesta en el signo. Inmediatamente notamos que este procedimiento es desconocido para el mercado interbancario FOREX, donde operan todos los bancos mundiales. Vamos a explicar por qué.",
            "Al comprar, por ejemplo, 1 millón (10 lotes) de euros por dólares USA (EUR/USD) con una fecha de liquidación específica a un tipo de cambio específico, el comerciante abre una posición larga en 1 millón de euros y una posición corta en 1 millón × el tipo de cambio de la operación — en dólares USA. ",
            "Supongamos que un comerciante vende 1 millón de EUR/USD con la misma fecha de liquidación a un tipo de cambio diferente. En este caso, la posición en euros será cero (1 millón — 1 millón = 0) y en dólares USA, el resultado de la operación de arbitraje (tipo de cambio de la transacción 1 — tipo de cambio de la transacción 2) * 1 millón), es decir, el comerciante no tendrá una posición en la moneda base (en este caso en euros): se cerrará. ",
            "Sucederá de esta manera, y no de otra manera: las reglas de contabilidad internacional y bancaria (contabilidad y comercio) de las operaciones de conversión hablan de esto.",
            "Al bloquear posiciones, todo sucede de manera diferente. ",
            [
                "En primer lugar, al realizar dos operaciones opuestas al mismo tiempo, el comerciante inmediatamente incurre en una pérdida en el Tamaño del spread (la diferencia entre las tasas de compra y venta). Al mismo tiempo, el comerciante no tiene una posición de moneda abierta real en el mercado Forex, por lo que no hay forma de trabajar estos fondos perdidos. ",
                "En segundo lugar, al abrir virtualmente dos posiciones, el comerciante paga cuando cambia la fecha de cambio para transferirlas a una nueva fecha. ",
                "En tercer lugar, después de realizar dos o tres operaciones en el futuro, el comerciante generalmente comienza a confundirse en sus posiciones y resultados financieros, lo que dificulta la evaluación de las acciones en el mercado. Si analizamos esto cuidadosamente, podemos ver que esta práctica solo beneficia al «broker milagroso » a través del cual trabaja el comerciante (el comerciante le paga todo el tiempo: propagación, transferencia de posición, etc.).",
            ],
            "Por estas razones, no apoyamos tales prácticas negativas porque respetamos y valoramos a nuestros Clientes.",
        ],
        active: false,
    },
    {
        id: 4,
        title: "¿Cómo cerrar una posición en el mercado Forex?",
        text: [
            "La posición se cierra realizando una transacción en el mismo par de divisas por la misma cantidad en la que se abre la posición, pero con el signo inverso. Por ejemplo, si un comerciante tiene una posición larga (compra) de 100 000 EUR/USD (1 lote), el comerciante necesita vender 100 000 EUR/USD para cerrarla.",
            "Si un comerciante tiene una posición corta (venta) del mismo volumen en el mismo par, debe comprar 100 000 EUR/USD para cerrarla.",
            "El principio de funcionamiento del mercado Forex está completamente sujeto a las leyes de la aritmética:",
            "100 000–100 000=0, o -100 000+100 000=0, o 200 000–100 000–100 000=0.",
        ],
        active: false,
    },
    {
        id: 5,
        title: "¿Cómo colocar órdenes de ejecución inmediata en la plataforma de negociación?",
        text: [
            "el cuadro de diálogo de creación de órdenes se puede utilizar de varias maneras:",
            [
                "En la ventana «Revisión del mercado», seleccione la herramienta deseada y ejecute el comando \"nueva orden\" desde el menú contextual.",
                'Presione la tecla de acceso rápido «F9». En este caso, la configuración del terminal sustituirá el símbolo en la ventana de la orden.',
                'Ejecute El comando nuevo pedido en el menú Herramientas o en la barra de herramientas Estándar.',
            ],
            'Después de realizar una de las acciones anteriores, se abrirá la ventana de colocación de la orden correspondiente al tipo de ejecución del instrumento seleccionado: «ejecución Inmediata». ',
            'En este modo, la orden de mercado se ejecuta al precio de mercado actual. El terminal sustituye automáticamente los precios actuales en la orden. Al abrir una posición en este modo, en el campo «Símbolo» de la Ventana «Solicitud», seleccione el instrumento financiero en el que desea realizar la transacción.',
            "En el bloque de parámetros de la orden, complete los siguientes campos:",
            [
                "Volumen-volumen de la orden en lotes (1 lote = 100 000 unidades de la moneda base).",
                "Stop Loss-el nivel de Stop loss en los precios o el número de puntos del precio especificado en la orden, dependiendo de la configuración del terminal. Si deja un valor nulo en el campo, este tipo de orden no se adjuntará.",
                "Take Profit el nivel de Take Profit en los precios o el número de puntos del precio especificado en la orden, dependiendo de la configuración del terminal. Si deja cero en el campo, este tipo de orden no se adjuntará.",
            ],
            'Una vez que se hayan especificado todos los datos necesarios, debe hacer clic en el botón «Vender» o «Comprar». En este caso, se envía una orden al distribuidor para abrir una posición corta o larga. Para cerrar completamente una posición, debe ejecutar el comando «Cerrar posición» en su menú contextual en la pestaña «Comercio». La ventana de cierre de posición es similar a la ventana de apertura de la posición, excepto el botón Cerrar.',
        ],
        active: false,
    },
    {
        id: 6,
        title:
            "¿Qué tipos de órdenes pendientes hay, y Cuál es la diferencia entre los detalles de su ejecución?",
        text: [
            "Hay dos órdenes en FOREX: Stop loss y Take Profit.",
            "El tipo de orden no depende de la acción que realice con la posición de divisas del comerciante. Para determinar el tipo de orden no importa, es una orden para abrir una posición, cerrar, aumentar o disminuir. Y el tipo de orden depende del nivel de precio de esa orden en relación con el nivel actual de precios en el mercado:",
            [
                "Una orden de COMPRA a un precio más ALTO que el nivel actual de precios en el mercado es una orden de STOP-LOSS (en MetaTrader 5 — Buy Stop).",
                "Una orden de COMPRA a un precio POR debajo del nivel actual de precios en el mercado es una orden de tipo TAKE PROFIT (en MetaTrader 5 — Buy Limit).",
                "Una orden de VENTA a un precio POR debajo del nivel actual de precios en el mercado es una orden STOP-LOSS (en MetaTrader 5 — Sell Stop).",
                "Una orden SELL a un precio POR encima del nivel actual de precios en el mercado es una orden TAKE PROFIT (en MetaTrader 5 — Sell Limit).",
            ],
            "Dependiendo del tipo de orden (stop-loss o take-Profit), la ejecución de las órdenes en FOREX también es diferente.",
            "La orden Take Profit se ejecuta cuando el mercado alcanza el precio de la orden de tal manera que el precio de la orden corresponde a la cotización del distribuidor de divisas.",
            "Las órdenes de Stop loss se ejecutan al precio siguiente al especificado en la orden. Por lo tanto, la orden de Stop loss se puede ejecutar tanto al precio especificado en la orden (si el mercado ha alcanzado este nivel y se ha ralentizado en él) como al precio peor indicado en la orden (si el mercado, habiendo alcanzado el precio de la orden, continuó avanzando). ",
            "Al mismo tiempo, el valor de la desviación puede ser 1-3 puntos del precio de la orden (la situación más común con un movimiento suave del mercado), y diferir en un gran valor (una situación rara característica de un movimiento brusco del mercado en el momento de la publicación de noticias económicas importantes).",
        ],
        active: false,
    },
    {
        id: 7,
        title: "¿Con qué depósito MÍNIMO puede comenzar a operar en Forex?",
        text: "El monto mínimo de depósito (Margen) requerido para realizar transacciones con un par de divisas específico se calcula como la relación entre el volumen de la transacción y el apalancamiento máximo, en dólares o euros equivalentes, dependiendo de la moneda de la cuenta Activa.",
        active: false,
    },
]
let de_tabs = [
    {
        id: 1,
        title: "Was ist FOREX?",
        text: [
            "Die heutige Gestalt des internationalen Devisenmarktes entstand, nachdem die Mitgliedstaaten der EU im März 1973 das System fester Wechselkurse aufgegeben hatten.",
            "Ein Merkmal des FOREX Marktes ist, dass er als Interbankenmarkt keinen bestimmten Handelsplatz hat, Aktien oder Devisentermingeschäfte. ",
            "Der Handel findet innerhalb einer Woche über das Telefon und über die Terminals von elektronischen Brokerage Systemen zwischen Hunderten von Banken auf der ganzen Welt statt.",
            "Angesichts der Tatsache, dass die meisten Transaktionen auf dem Interbankenwährungsmarkt im Wert von 5-10 Millionen Dollar abgeschlossen sind, kann nicht jeder eine solche Menge an Währung mit Lieferung kaufen. ",
            "Daher sind die Hauptteilnehmer eines solchen Marktes Geschäftsbanken, Zentralbanken, Außenhandelsunternehmen, große Investmentgesellschaften und Fonds. Aufgrund der Möglichkeit, das Prinzip des Margin-Handels (oder Leverage) auf dem FOREX Markt zu verwenden, können sowohl Einzelpersonen als auch kleine Unternehmen an diesem Markt arbeiten. ",
            "Leverage ermöglicht es ihnen, Transaktionen für Beträge zu tätigen, die weit über ihr Geld hinausgehen, und die liquide Deckung (Kaution) wird als Sicherheit verwendet. Solche Teilnehmer am internationalen Devisenmarkt führen Schiedsverfahren mit Devisen oder Derivaten durch, deren Basiswert die Währung ist: Sie führen Kauf und Verkaufsgeschäfte aus, um durch Änderung des Wechselkurses Arbitragegewinne zu erzielen. ",
            "In diesem Fall findet keine echte Lieferung der gekauften Währung statt, und der Kunde schließt seine Position einfach am Abrechnungsdatum (er macht eine umgekehrte Transaktion). Es handelt sich um Transaktionen mit einer «Schulter», die das Zehnfache der Einzahlungsgröße beträgt. ",
            "Dank der Hebelwirkung können solche Kunden ihre Währungsrisiken absichern (versichern). und der Kunde schließt seine Position einfach zum Abrechnungsdatum (er macht eine umgekehrte Transaktion). Es handelt sich um Transaktionen mit einer «Schulter», die das Zehnfache der Einzahlungsgröße beträgt. ",
            "Dank der Hebelwirkung können solche Kunden ihre Währungsrisiken absichern (versichern). und der Kunde schließt seine Position einfach zum Abrechnungsdatum (macht eine umgekehrte Transaktion). Es handelt sich um Transaktionen mit einer «Schulter», die das Zehnfache der Einzahlungsgröße beträgt. ",
            "Dank der Hebelwirkung können solche Kunden ihre Währungsrisiken absichern (versichern)."
        ],
        active: false,
    },
    {
        id: 2,
        title: "Wer bietet Dienstleistungen auf dem FOREX Markt an",
        text: [
            "Die wichtigsten Gruppen von Organisationen, die FOREX Dienste anbieten:",
            [
                "Banken mit einer allgemeinen (Währungs) Lizenz;",
                "Lizenzierte Forex Händler;",
                "Offshore Handelszentren.",
            ],
        ],
        active: false,
    },
    {
        id: 3,
        title:
            "Warum bietet VTB Forex den Kunden nicht die Möglichkeit, lokalisierte Positionen zu eröffnen?",
        text: [
            "Bei der Lokalisierung einer Position wird normalerweise die Möglichkeit verstanden, eine neue Position zu eröffnen, die einem Währungspaar und einer Größe entspricht, die bereits geöffnet ist, aber durch Vorzeichen entgegengesetzt ist. Beachten Sie sofort, dass dieses Verfahren dem Interbanken FOREX Markt unbekannt ist, in dem alle Weltbanken tätig sind. Lassen Sie uns erklären, warum.",
            "Wenn Sie beispielsweise 1 Million (10 Lose) Euro für Dollar USA (EUR/USD) mit einem bestimmten Abrechnungsdatum zu einem bestimmten Kurs kaufen, öffnet der Händler eine Long Position um 1 Million Euro und eine Short Position um 1 Million × der Transaktionsverlauf ist in Dollar USA. ",
            "Angenommen, ein Händler verkauft 1 Million EUR/USD mit demselben Abrechnungsdatum zu einem anderen Kurs. In diesem Fall wird die Position in Euro Null (1 Million — 1 Million = 0) und in USA — Dollar das Ergebnis einer Arbitrage-Transaktion (Kurs 1 — Kurs 2) * 1 Million) sein, das heißt, der Händler wird keine Position in der Basiswährung (in diesem Fall in Euro) haben: Sie wird geschlossen. ",
            "Dies geschieht genau so und nicht anders: Dies wird durch die Regeln für internationale und Bankbuchhaltung (Buchhaltung und Handel) von Konvertierungstransaktionen besprochen.",
            "Wenn Sie Positionen lokalisieren, geschieht dies anders. ",
            [
                "Erstens, wenn der Händler zwei entgegengesetzte Trades gleichzeitig macht, trägt er sofort einen Verlust in Höhe des Spreads (die Differenz zwischen den Kauf und Verkaufskursen). In diesem Fall hat der Händler keine echte offene Devisenposition auf dem Forex-Markt, daher gibt es keine Möglichkeit, diese verlorenen Gelder abzuarbeiten. ",
                "Zweitens, wenn der Händler zwei Positionen virtuell öffnet, zahlt er, wenn er das Währungsdatum ändert, um sie auf ein neues Datum zu übertragen. ",
                "Drittens beginnt der Händler nach zwei oder drei Trades in der Zukunft in der Regel mit seinen Positionen und finanziellen Ergebnissen zu verwirren, was es schwierig macht, die Maßnahmen auf dem Markt zu bewerten. Wenn Sie dies sorgfältig analysieren, können Sie sehen, dass eine solche Praxis nur dem «Wundermakler» zugute kommt, durch den der Händler arbeitet (der Händler zahlt ihm die ganze Zeit: Spread, Positionstransfer usw.).",
            ],
            "Aus diesen Gründen unterstützen wir solche negativen Praktiken nicht, weil wir unsere Kunden respektieren und schätzen.",
        ],
        active: false,
    },
    {
        id: 4,
        title: "Wie schließe ich eine Position auf dem Forex Markt?",
        text: [
            "Die Position wird geschlossen, indem Sie eine Transaktion im selben Währungspaar für den gleichen Betrag, um den die Position geöffnet wird, mit dem umgekehrten Vorzeichen abschließen. Wenn beispielsweise ein Händler eine Long-Position (Kauf) für EUR/USD mit einem Volumen von 100 000 (1 Lot) hat, muss der Händler 100 000 EUR/USD verkaufen, um sie zu schließen.",
            "Wenn ein Händler eine Short-Position (Verkauf) des gleichen Volumens im selben Paar hat, müssen Sie 100 000 EUR/USD kaufen, um sie zu schließen.",
            "Das Prinzip des Forex Marktes unterliegt vollständig den Gesetzen der Arithmetik:",
            "100 000–100 000=0, oder -100 000+100 000=0, oder 200 000–100 000–100 000=0.",
        ],
        active: false,
    },
    {
        id: 5,
        title: "Wie kann ich sofortige Ausführungsaufträge auf einer Handelsplattform platzieren?",
        text: [
            "das Dialogfeld Auftragserstellung kann auf verschiedene Arten erstellt werden:",
            [
                "Wählen Sie im Fenster «Marktübersicht» das gewünschte Werkzeug aus und führen Sie den Befehl «Neue Order» aus dem Kontextmenü aus.",
                'Drücken Sie die Tastenkombination «F9». In diesem Fall ersetzen die Terminal Einstellungen das Symbol im Order Fenster.',
                'Führen Sie im Menü «Werkzeuge» oder in der Symbolleiste «Standard» den Befehl «Neue Bestellung» aus.',
            ],
            'Nachdem Sie eine der oben genannten Aktionen ausgeführt haben, wird das Fenster für die Orderausstellung geöffnet, das dem Ausführungstyp des ausgewählten Instruments entspricht: «Sofortige Ausführung». ',
            'In diesem Modus wird eine Marktorder zum aktuellen Marktpreis ausgeführt. Das Terminal fügt die aktuellen Preise automatisch in die Order ein. Wenn Sie eine Position in diesem Modus öffnen, wählen Sie im Feld «Symbol» des Fensters «Angebot» das Finanzinstrument aus, für das Sie eine Transaktion tätigen möchten.',
            "Füllen Sie im Parameterblock der Order die folgenden Felder aus:",
            [
                "Volumen — das Volumen der Order in Lots (1 Lot = 100 000 Einheiten der Basiswährung).",
                "Stop Loss — Das Niveau des Stop Loss in den Preisen oder die Anzahl der Punkte des in der Order angegebenen Preises, abhängig von den Einstellungen des Terminals. Wenn Sie einen Wert von Null im Feld belassen, wird diese Art von Order nicht angehängt.",
                "Take Profit — die Höhe des Take Profit in den Preisen oder die Anzahl der Punkte des in der Order angegebenen Preises, abhängig von den Einstellungen des Terminals. Wenn Sie einen Wert von Null im Feld belassen, wird dieser Ordertyp nicht angehängt.",
            ],
            'Nachdem alle erforderlichen Daten eingegeben wurden, müssen Sie auf die Schaltfläche «Verkaufen» oder «Kaufen» klicken. In diesem Fall wird dem Händler eine Order gesendet, eine Short oder Long-Position zu öffnen. Um eine Position vollständig zu schließen, müssen Sie den Befehl "Position schließen" im Kontextmenü auf der Registerkarte "Handel" ausführen. Das Fenster zum Schließen einer Position ähnelt dem Fenster zum Öffnen einer Position, mit Ausnahme der Schaltfläche «Schließen».',
        ],
        active: false,
    },
    {
        id: 6,
        title:
            "Welche Arten von Pending Orders gibt es, und was ist der Unterschied zwischen den Besonderheiten ihrer Ausführung?",
        text: [
            "Es gibt zwei Orders auf FOREX: einen Stop-Loss und einen Take-Profit.",
            "Die Art der Order hängt nicht davon ab, welche Aktion sie mit der Devisenposition des Händlers durchführt. Um den Ordertyp zu bestimmen, spielt es keine Rolle, ob es sich um eine Order handelt, um eine Position zu öffnen, zu schließen, zu erhöhen oder zu verringern. Und die Art der Order hängt vom Preisniveau dieser Order im Verhältnis zum aktuellen Preisniveau auf dem Markt ab:",
            [
                "Eine Kauforder zu einem höheren Preis als das aktuelle Preisniveau auf dem Markt ist eine STOP-LOSS Order (bei MetaTrader 5 — Buy Stop). ",
                "Eine Kauforder zu einem Preis unterhalb des aktuellen Marktpreisniveaus ist eine TAKE PROFIT Order (in MetaTrader5 — Buy Limit)",
                "Eine Verkaufsorder zu einem Preis unter dem aktuellen Preisniveau auf dem Markt ist eine STOP-LOSS Order (in MetaTrader 5 — Sell Stop)",
                "Eine SELL-Order zu einem Preis ÜBER dem aktuellen Marktpreisniveau ist eine TAKE PROFIT Order (in MetaTrader 5 — Sell Limit)",
            ],
            "Abhängig von der Art der Order (Stop-loss oder Take -Profit) unterscheidet sich auch die Ausführung von FOREX-Orders.",
            "Eine Take-Profit Order wird ausgeführt, wenn der Markt den Order Preis so erreicht, dass der Order Preis dem Angebot des Forex-Händlers entspricht.",
            "Stop-Loss Aufträge werden zu dem Preis ausgeführt, der dem in der Order angegebenen folgt. Daher kann eine Stop-Loss-Order sowohl zu dem in der Order angegebenen Preis ausgeführt werden (wenn der Markt dieses Niveau erreicht hat und sich verlangsamt hat) als auch zu einem niedrigeren Preis als der in der Order angegebene Preis (wenn der Markt, nachdem er den Order-Preis erreicht hat, weiter voranschreitet). ",
            "Dabei kann der Wert der Abweichung sowohl 1-3 Punkte vom Orderpreis sein (die häufigste Situation bei einer reibungslosen Marktbewegung), als auch um einen größeren Wert variieren (eine seltene Situation, die zum Zeitpunkt der Veröffentlichung wichtiger Wirtschaftsnachrichten für eine plötzliche Marktbewegung charakteristisch ist).",
        ],
        active: false,
    },
    {
        id: 7,
        title: "Mit welcher Mindesteinzahlung können Sie mit dem Forex Handel beginnen?",
        text: "Der Mindesteinzahlungsbetrag (Margin), der für Transaktionen mit einem bestimmten Währungspaar erforderlich ist, wird als Verhältnis zwischen dem Transaktionsvolumen und dem maximalen Hebelbetrag berechnet, in Dollar oder Euro, abhängig von der Währung des aktiven Kontos.",
        active: false,
    },
]
export { eng_tabs, ru_tabs, pl_tabs, it_tabs, es_tabs, de_tabs }