import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/education/1/1.png";
import classes from "./Practice.module.scss";
import { Trans, useTranslation } from "react-i18next";
const Practice = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.practice}>
      <div className={classes.practiceBody}>
        <div className="container">
          <div className={classes.practiceRow}>
            <div className={classes.practiceRowLeft}>
              <div
                className={[classes.practiceRowLeftTitle, "title"].join(" ")}
              >
                {t("education_second__title")}
              </div>
              <div
                className={[classes.practiceRowLeftSubtitle, "subtitle"].join(
                  " "
                )}
              >
                <Trans components={{ span: <span></span> }}>
                  {t("education_second__subtitle")}
                </Trans>
              </div>
              <div className={classes.practiceRowLeftList}>
                <p className="subtitle">{t("education_second__item1")}</p>
                <p className="subtitle">{t("education_second__item2")}</p>
                <p className="subtitle">{t("education_second__item3")}</p>
                <p className="subtitle">{t("education_second__item4")}</p>
              </div>
              <div className={classes.practiceRowLeftStart}>
                <Button></Button>
              </div>
            </div>
            <div className={classes.practiceRowRight}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Practice;
