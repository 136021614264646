import React from "react";
import classes from "./TradeCFD.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
const TradeCFD = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.tradeCFDContainer}>
      <div className="container">
        <div className={classes.tradeCFDWrap}>
          <h3 className={["header-default", classes.tradeCFDTitle].join(" ")}>
            {t("main_three__title")}
          </h3>
          <p className={["text-default", classes.tradeCFDText].join(" ")}>
            {t("main_three__subtitle2")}
          </p>
          <p className={["text-default", classes.tradeCFDText].join(" ")}>
            {t("main_three__subtitle1")}
          </p>
          <Button classesName={classes.tradeCFDLink}>
            {t("btn_start_now")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TradeCFD;
