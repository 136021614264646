import React from "react";
import classes from "./SoWhat.module.scss";
import { Trans, useTranslation } from "react-i18next";
const SoWhat = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.soWhat}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("start_six__title")}
        </div>
        <div className={classes.text}>
          <Trans>{t("start_six__subtitle")}</Trans>
        </div>
      </div>
    </div>
  );
};

export default SoWhat;
