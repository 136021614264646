import React, { useState, useEffect } from "react";
import classes from "./QA.module.scss";
import Spoiler from "../../GlobalComponents/Spoiler/Spoiler";
import { useTranslation } from "react-i18next";
import {
  eng_tabs,
  ru_tabs,
  es_tabs,
  it_tabs,
  de_tabs,
  pl_tabs,
} from "../../../config/locales";
const QA = () => {
  const { t } = useTranslation();
  const [spoilers, setSpoilers] = useState([]);

  useEffect(() => {
    if (t("lang") === "en") {
      setSpoilers(eng_tabs);
    } else if (t("lang") === "de") {
      setSpoilers(de_tabs);
    } else if (t("lang") === "pl") {
      setSpoilers(pl_tabs);
    } else if (t("lang") === "ru") {
      setSpoilers(ru_tabs);
    } else if (t("lang") === "es") {
      setSpoilers(es_tabs);
    } else if (t("lang") === "it") {
      setSpoilers(it_tabs);
    }
    // eslint-disable-next-line
  }, [t("lang")]);

  return (
    <div className="container">
      <div className={classes.qa}>
        <div className={[classes.header, "header-default"].join(" ")}>
          {t("qa_four__title")}
        </div>
        <div className={classes.faqWrapper}>
          {spoilers.map((i, index) => {
            return (
              <Spoiler
                {...i}
                key={index}
                clickEvent={() => {
                  setSpoilers(
                    spoilers.map((spoilerI) => {
                      if (spoilerI.id === i.id) {
                        return { ...spoilerI, active: !i.active };
                      }
                      return { ...spoilerI, active: false };
                    })
                  );
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default QA;
