import React from "react";
import classes from "./Deposits.module.scss";
import { useTranslation } from "react-i18next";
const Deposits = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.deposits}>
      <div className={classes.depositsBody}>
        <div className="container">
          <div className={[classes.depositsTitle, "title"].join(" ")}>
            {t("accounts_three__title")}
          </div>
          <div className={[classes.depositsSubtitle, "subtitle"].join(" ")}>
            {t("accounts_three__subtitle")}
          </div>
          <div className={classes.depositsWrapperSecond}>
            <div className={classes.depositsWrapper}>
              <div className={classes.depositsHeaderRow}>
                <div className={classes.depositsHeaderRowColumn}>
                  {t("accounts_three_row1__column1")}
                </div>
                <div className={classes.depositsHeaderRowColumn}>
                  {" "}
                  {t("accounts_three_row1__column2")}
                </div>
                <div className={classes.depositsHeaderRowColumn}></div>
                <div className={classes.depositsHeaderRowColumn}>
                  {t("accounts_three_row1__column4")}
                </div>
                <div className={classes.depositsHeaderRowColumn}></div>
              </div>
              <div className={classes.depositsHeaderRow}>
                <div className={classes.depositsHeaderRowColumn}></div>
                <div className={classes.depositsHeaderRowColumn}>
                  {t("accounts_three_row2__column2")}
                </div>
                <div className={classes.depositsHeaderRowColumn}>
                  {" "}
                  {t("accounts_three_row2__column3")}
                </div>
                <div className={classes.depositsHeaderRowColumn}>
                  {t("accounts_three_row2__column4")}
                </div>
                <div className={classes.depositsHeaderRowColumn}>
                  {" "}
                  {t("accounts_three_row2__column5")}
                </div>
              </div>
              <div className={classes.depositsRow}>
                <div className={classes.depositsRowColumn}>
                  <span> {t("accounts_three_row3__column1")}</span>
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row3__column2")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row3__column3")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row3__column4")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row3__column5")}
                </div>
              </div>
              <div className={classes.depositsRow}>
                <div className={classes.depositsRowColumn}>
                  <span> {t("accounts_three_row4__column1")}</span>
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row4__column2")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {t("accounts_three_row4__column3")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {t("accounts_three_row4__column4")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {t("accounts_three_row4__column5")}
                </div>
              </div>
              <div className={classes.depositsRow}>
                <div className={classes.depositsRowColumn}>
                  <span> {t("accounts_three_row5__column1")}</span>
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row5__column2")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row5__column3")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row5__column4")}
                </div>
                <div className={classes.depositsRowColumn}>
                  {" "}
                  {t("accounts_three_row5__column5")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposits;
