import React from "react";
import Button from "../../GlobalComponents/Button/Button";
import classes from "./Education.module.scss";
import { useTranslation } from "react-i18next";
const Education = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.education}>
      <div className={classes.educationBody}>
        <div className="container">
          <div className={classes.educationTitle}>
            {t("education_first__title")}
          </div>
          <div className={[classes.educationSubtitle, "subtitle"].join(" ")}>
            {t("education_first__subtitle")}
          </div>
          <div className={classes.educationStart}>
            <Button></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
