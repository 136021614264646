import React from "react";
import classes from "./TrustedCards.module.scss";
import icon_01 from "../../../assets/img/StartPage/trusted_card_01.png";
import icon_02 from "../../../assets/img/StartPage/trusted_card_02.png";
import icon_03 from "../../../assets/img/StartPage/trusted_card_03.png";
import { useTranslation } from "react-i18next";
const cards = [
  {
    id: 0,
    icon: icon_01,
    header: "start_four_item1__title",
    text: "start_four_item1__subtitle",
  },
  {
    id: 1,
    icon: icon_02,
    header: "start_four_item2__title",
    text: "start_four_item2__subtitle",
  },
  {
    id: 2,
    icon: icon_03,
    header: "start_four_item3__title",
    text: "start_four_item3__subtitle",
  },
];

const TrustedCards = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.trustedCards}>
        {cards.map((card) => (
          <div className={classes.card} key={card.id}>
            <img
              className={[classes.icon, "no-select"].join(" ")}
              src={card.icon}
              alt=""
            />
            <div className={classes.content}>
              <div className={classes.header}>{t(card.header)}</div>
              <div className={classes.text}>{t(card.text)}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustedCards;
