import React from "react";
import classes from "./CanTrade.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
const CanTrade = () => {
  const { t } = useTranslation();

  return (
    <div className="container">
      <h2 className={["header-default", classes.canTradeTitle].join(" ")}>
        {t("main_seven__title")}
      </h2>
      <div className={classes.canTradeWrap}>
        <div className={classes.canTradeList}>
          <div className={classes.canTradeItem}>
            <h5 className={classes.canTradeItemTitle}>
              {t("main_seven__subtitle1")}
            </h5>
            <p className={classes.canTradeItemText}>
              {t("main_seven__subtext1")}
            </p>
          </div>
          <div className={classes.canTradeItem}>
            <h5 className={classes.canTradeItemTitle}>
              {t("main_seven__subtitle2")}
            </h5>
            <p className={classes.canTradeItemText}>
              {t("main_seven__subtext2")}
            </p>
          </div>
          <div className={classes.canTradeItem}>
            <h5 className={classes.canTradeItemTitle}>
              {t("main_seven__subtitle3")}
            </h5>
            <p className={classes.canTradeItemText}>
              {t("main_seven__subtext3")}
            </p>
          </div>
          <div className={classes.canTradeItem}>
            <h5 className={classes.canTradeItemTitle}>
              {t("main_seven__subtitle4")}
            </h5>
            <p className={classes.canTradeItemText}>
              {t("main_seven__subtext4")}
            </p>
          </div>
          <div className={classes.canTradeItem}>
            <h5 className={classes.canTradeItemTitle}>
              {t("main_seven__subtitle5")}
            </h5>
            <p className={classes.canTradeItemText}>
              {t("main_seven__subtext5")}
            </p>
          </div>
          <div className={classes.canTradeItem}>
            <h5 className={classes.canTradeItemTitle}>
              {t("main_seven__subtitle6")}
            </h5>
            <p className={classes.canTradeItemText}>
              {t("main_seven__subtext6")}
            </p>
          </div>
          <div className={classes.canTradeItem}>
            <h5 className={classes.canTradeItemTitle}>
              {t("main_seven__subtitle7")}
            </h5>
            <p className={classes.canTradeItemText}>
              {t("main_seven__subtext7")}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.canTradeLinkWrap}>
        <Button>{t("btn_start_now")}</Button>
      </div>
    </div>
  );
};

export default CanTrade;
