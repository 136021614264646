import React from "react";
import classes from "./MoreForex.module.scss";
import { Trans, useTranslation } from "react-i18next";
import img from "../../../assets/img/MainPage/more-forex-img.png";

const MoreForex = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.moreForexSection}>
      <div className="container">
        <h2 className={["header-default", classes.moreForexTitle].join(" ")}>
          {t("main_six__title")}
        </h2>
        <div className={classes.moreForexRow}>
          <div className={classes.moreForexCol4}>
            <img src={img} alt="" className={classes.imgFluid} />
          </div>
          <div className={classes.moreForexCol8}>
            <div className={classes.moreForexListWrap}>
              <ul className={classes.moreForexList}>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item1")}</Trans>
                </li>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item2")}</Trans>
                </li>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item3")}</Trans>
                </li>
              </ul>
              <ul className={classes.moreForexList}>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item4")}</Trans>
                </li>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item5")}</Trans>
                </li>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item6")}</Trans>
                </li>
              </ul>
              <ul className={classes.moreForexList}>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item7")}</Trans>
                </li>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item8")}</Trans>
                </li>
                <li className={classes.moreForexItem}>
                  <Trans>{t("main_six__item9")}</Trans>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreForex;
