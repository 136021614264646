import React from "react";
import classes from "./Columns.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import { useTranslation } from "react-i18next";
const Columns = () => {
  const { t } = useTranslation();
  return (
    <div className={classes.columns}>
      <div className={classes.columnsBody}>
        <div className="container">
          <div className={classes.columnsRowWrapper}>
            <div className={classes.columnsRow}>
              <div className={classes.columnsRowColumn}>
                <div className={classes.columnsRowColumnItem}>
                  <div className={classes.columnsRowColumnItemLine}></div>
                  <div className={classes.columnsRowColumnItemTitle}>
                    {t("accounts_second_column1__title")}
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column1__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column1__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column2__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column2__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column3__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column3__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column4__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column4__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column5__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column5__text1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column5__text2")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column5__text3")}
                    </div>
                  </div>
                </div>
                <div className={classes.columnsRowColumnOpenBtn}>
                  <Button>{t("btn_check")}</Button>
                </div>
              </div>
              <div className={classes.columnsRowColumn}>
                <div className={classes.columnsRowColumnItem}>
                  <div className={classes.columnsRowColumnItemLine}></div>
                  <div className={classes.columnsRowColumnItemTitle}>
                    {t("accounts_second_column6__title")}
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column6__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column6__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column7__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column7__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column8__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column8__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column9__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column9__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column10__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column10__text1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column10__text2")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column10__text3")}
                    </div>
                  </div>
                </div>
                <div className={classes.columnsRowColumnOpenBtn}>
                  <Button>{t("btn_check")}</Button>
                </div>
              </div>
              <div className={classes.columnsRowColumn}>
                <div className={classes.columnsRowColumnItem}>
                  <div className={classes.columnsRowColumnItemLine}></div>
                  <div className={classes.columnsRowColumnItemTitle}>
                    {t("accounts_second_column11__title")}
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column11__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column11__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column12__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column12__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column13__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column13__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column14__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column14__text1")}
                    </div>
                  </div>
                  <div className={classes.columnsRowColumnItemBlock}>
                    <div className={classes.columnsRowColumnItemBlockTitle}>
                      {t("accounts_second_column15__subtitle1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column15__text1")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column15__text2")}
                    </div>
                    <div className={classes.columnsRowColumnItemBlockSubtitle}>
                      {t("accounts_second_column15__text3")}
                    </div>
                  </div>
                </div>
                <div className={classes.columnsRowColumnOpenBtn}>
                  <Button>{t("btn_check")}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Columns;
