import React, { useState, useEffect } from "react";
import classes from "./Header.module.scss";
import logo from "../../../assets/img/header/logo.svg";
import arrow from "../../../assets/img/header/arrow.svg";
import { headerRoutes } from "../../../router/index";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Cookies from "js-cookie";

const Header = () => {
  const { t } = useTranslation();
  const lang = Cookies.get('i18next');
  global.text = t;
  const languages = [
    { value: "ru", label: "Ru" },
    { value: "en", label: "En" },
    { value: "es", label: "Es" },
    { value: "it", label: "It" },
    { value: "de", label: "De" },
    { value: "pl", label: "Pl" },
    { value: "fr", label: "Fr" },
    { value: "pt", label: "Pt" },
    { value: "ro", label: "Ro" },
  ];
  const [localA, setLocalA] = useState(false);
  const [burgerA, setBurgerA] = useState(false);

  let location = useLocation();
  const [numNav, setNumNav] = useState(0);
  const [defaultOption, setDefaultOption] = useState(Cookies.get("i18next"));

  useEffect(() => {
    setDefaultOption(Cookies.get("i18next"));
  }, []);

  useEffect(() => {
    if (location.pathname === "/start") {
      document.querySelector("body").classList.remove("lock");
      setNumNav(1);
    } else if (location.pathname === "/accounts") {
      document.querySelector("body").classList.remove("lock");
      setNumNav(2);
    } else if (location.pathname === "/trading") {
      document.querySelector("body").classList.remove("lock");
      setNumNav(3);
    } else if (location.pathname === "/education") {
      document.querySelector("body").classList.remove("lock");
      setNumNav(4);
    } else if (location.pathname === "/about") {
      document.querySelector("body").classList.remove("lock");
      setNumNav(5);
    } else if (location.pathname === "/") {
      setNumNav(0);
      document.querySelector("body").classList.remove("lock");
    }
    if (location.pathname === "/login") {
      if (window.innerWidth > 768) {
        document.querySelector("body").classList.add("lock");
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.header}>
      <div className={classes.headerBody}>
        <div className="container">
          <div className={classes.headerRow}>
            <div
              className={
                burgerA
                  ? [classes.headerRowLogo, classes.active].join(" ")
                  : classes.headerRowLogo
              }
            >
              <Link
                to="/"
                onClick={() => {
                  setNumNav(0);
                  document.querySelector("body").classList.remove("lock");
                }}
              >
                <img src={logo} alt="" />
              </Link>
            </div>

            <div className={classes.headerRowRight}>
              <div
                className={
                  burgerA
                    ? [classes.headerRowCenterWrapper, classes.active].join(" ")
                    : classes.headerRowCenterWrapper
                }
              >
                <div className={classes.headerRowCenter}>
                  {// eslint-disable-next-line
                  headerRoutes.map((i, index) => {
                    if (i.id === 1) {
                      return (
                        <Link
                          key={index}
                          to={i.path}
                          onClick={() => {
                            setNumNav(1);
                            document
                              .querySelector("body")
                              .classList.remove("lock");
                          }}
                          className={
                            numNav === 1
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    } else if (i.id === 2) {
                      return (
                        <Link
                          key={index}
                          onClick={() => {
                            document
                              .querySelector("body")
                              .classList.remove("lock");
                            setNumNav(2);
                            document
                              .querySelector("body")
                              .classList.add("lock");
                          }}
                          to={i.path}
                          className={
                            numNav === 2
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    } else if (i.id === 3) {
                      return (
                        <Link
                          onClick={() => {
                            document
                              .querySelector("body")
                              .classList.remove("lock");
                            setNumNav(3);
                            document
                              .querySelector("body")
                              .classList.remove("lock");
                          }}
                          key={index}
                          to={i.path}
                          className={
                            numNav === 3
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    } else if (i.id === 4) {
                      return (
                        <Link
                          onClick={() => {
                            document
                              .querySelector("body")
                              .classList.remove("lock");
                            setNumNav(4);
                            document
                              .querySelector("body")
                              .classList.remove("lock");
                          }}
                          key={index}
                          to={i.path}
                          className={
                            numNav === 4
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    } else if (i.id === 5) {
                      return (
                        <Link
                          onClick={() => {
                            document
                              .querySelector("body")
                              .classList.remove("lock");
                            setNumNav(5);
                            document
                              .querySelector("body")
                              .classList.remove("lock");
                          }}
                          key={index}
                          to={i.path}
                          className={
                            numNav === 5
                              ? [
                                  classes.headerRowCenterItem,
                                  classes.active,
                                ].join(" ")
                              : classes.headerRowCenterItem
                          }
                        >
                          {t(i.text)}
                        </Link>
                      );
                    }
                  })}
                  <div className={classes.wrap2}>
                    <div
                      className={[
                        classes.headerRowRightLocalization,
                        classes.mob,
                      ].join(" ")}
                      onClick={() => {
                        setLocalA(!localA);
                      }}
                    >
                      <div
                        className={
                          localA
                            ? burgerA
                              ? [
                                  classes.headerRowRightLocalizationTitle,
                                  classes.active,
                                  classes.white,
                                ].join(" ")
                              : [
                                  classes.headerRowRightLocalizationTitle,
                                  classes.active,
                                ].join(" ")
                            : burgerA
                            ? [
                                classes.headerRowRightLocalizationTitle,
                                classes.white,
                              ].join(" ")
                            : classes.headerRowRightLocalizationTitle
                        }
                      >
                        {defaultOption}
                        <img src={arrow} alt="" />
                      </div>
                      <div
                        className={
                          localA
                            ? [
                                classes.headerRowRightLocalizationItems,
                                classes.active,
                              ].join(" ")
                            : classes.headerRowRightLocalizationItems
                        }
                      >
                        {languages.map((i, index) => {
                          return (
                            <p
                              key={index}
                              onClick={() => {
                                i18n.changeLanguage(i.value);
                                setDefaultOption(i.value);
                              }}
                            >
                              {i.value}
                            </p>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classes.headerRowRightLocalization}
                onClick={() => {
                  setLocalA(!localA);
                }}
              >
                <div
                  className={
                    localA
                      ? burgerA
                        ? [
                            classes.headerRowRightLocalizationTitle,
                            classes.active,
                            classes.white,
                          ].join(" ")
                        : [
                            classes.headerRowRightLocalizationTitle,
                            classes.active,
                          ].join(" ")
                      : burgerA
                      ? [
                          classes.headerRowRightLocalizationTitle,
                          classes.white,
                        ].join(" ")
                      : classes.headerRowRightLocalizationTitle
                  }
                >
                  {defaultOption}
                  <img src={arrow} alt="" />
                </div>
                <div
                  className={
                    localA
                      ? [
                          classes.headerRowRightLocalizationItems,
                          classes.active,
                        ].join(" ")
                      : classes.headerRowRightLocalizationItems
                  }
                >
                  {languages.map((i, index) => {
                    return (
                      <p
                        key={index}
                        onClick={() => {
                          i18n.changeLanguage(i.value);
                          setDefaultOption(i.value);
                        }}
                      >
                        {i.value}
                      </p>
                    );
                  })}
                </div>
              </div>

              <a href={`https://user.asset-ace.one/login?lang=${lang}`} className={classes.headerRowRightSignBtn}>
                {t("header__login")}
              </a>
              <a href={`https://user.asset-ace.one/signup?lang=${lang}`} className={classes.headerRowRightOpenBtn}>
                {t("header__signup")}
              </a>
              <div
                className={
                  burgerA
                    ? [classes.headerRowRightBurger, classes.active].join(" ")
                    : classes.headerRowRightBurger
                }
                onClick={() => {
                  setBurgerA(!burgerA);
                  if (burgerA) {
                    document
                      .querySelector("#globalWrap")
                      .classList.remove("blur");
                  } else {
                    document.querySelector("#globalWrap").classList.add("blur");
                  }
                }}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
