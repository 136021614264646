import React from "react";
import AlwaysGiving from "../../components/StartPage/AlwaysGiving/AlwaysGiving";
import Banner from "../../components/StartPage/Banner/Banner";
import Header from '../../components/GlobalComponents/Header/Header';
import Footer from '../../components/GlobalComponents/Footer/Footer';
import classes from './StartPage.module.scss';
import TrustedPartner from "../../components/StartPage/TrustedPartner/TrustedPartner";
import TrustedCards from "../../components/StartPage/TrustedCards/TrustedCards";
import SoWhat from "../../components/StartPage/SoWhat/SoWhat";
import WhatEnergizes from "../../components/StartPage/WhatEnergizes/WhatEnergizes";
import DiscoverMore from "../../components/StartPage/DiscoverMore/DiscoverMore";
import ReadyTo from "../../components/StartPage/ReadyTo/ReadyTo";
import StartApp from "../../components/MainPage/StartApp/StartApp";

const StartPage = () => {
    return (
        <div>
            <div className='relative z-1'>
                <div className={classes.bannerBG}></div>
                <Header />
                <Banner />
            </div>
            <div className='relative z-1'>
                <div className={classes.pageBG}></div>
                <AlwaysGiving />
                <TrustedPartner />
                <TrustedCards />
                <StartApp />
                <SoWhat />
                <WhatEnergizes />
                <DiscoverMore />
                <ReadyTo />
                <Footer />
            </div>
        </div>
    );
};

export default StartPage;
