import React, { useRef, useEffect } from "react";
import img1 from "../../../assets/img/education/3/1.png";
import img2 from "../../../assets/img/education/3/2.png";
import classes from "./What.module.scss";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useTranslation } from "react-i18next";
const What = () => {
  const { t } = useTranslation();
  const refLeft = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const element = refLeft.current;

    if (document.documentElement.clientWidth > 1650) {
      gsap.timeline().fromTo(
        element.querySelector(".coin_right"),
        {
          y: -200,
        },
        {
          y: 150,
          scrollTrigger: {
            trigger: element,
            start: "top bottom",
            end: "top center",
            scrub: 2,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`.${classes.whatRowLeft}`),
        {
          y: 200,
        },
        {
          y: 0,
          scrollTrigger: {
            trigger: element,
            start: "top bottom",
            end: "top center",
            scrub: 2,
            id: "scrub",
          },
        }
      );
    }
    if (
      document.documentElement.clientWidth > 992 &&
      document.documentElement.clientWidth < 1650
    ) {
      gsap.timeline().fromTo(
        element.querySelector(".coin_right"),
        {
          y: -200,
        },
        {
          y: 100,
          scrollTrigger: {
            trigger: element,
            start: "top bottom",
            end: "top center",
            scrub: 2,
            id: "scrub",
          },
        }
      );
      gsap.timeline().fromTo(
        element.querySelector(`.${classes.whatRowLeft}`),
        {
          y: 200,
        },
        {
          y: 0,
          scrollTrigger: {
            trigger: element,
            start: "top bottom",
            end: "top center",
            scrub: 2,
            id: "scrub",
          },
        }
      );
    }
  }, []);

  return (
    <div className={classes.what} ref={refLeft}>
      <div className={classes.whatBody}>
        <div className="container">
          <div className={classes.whatRow}>
            <div className={classes.whatRowLeft}>
              <div className={[classes.whatRowLeftTitle, "title"].join(" ")}>
                {t("education_five__title")}
              </div>
              <div
                className={[classes.whatRowLeftSubtitle, "subtitle"].join(" ")}
              >
                {t("education_five__subtitle1")}
              </div>
              <div
                className={[classes.whatRowLeftSubtitle, "subtitle"].join(" ")}
              >
                {t("education_five__subtitle2")}
              </div>
              <div className={classes.whatRowLeftWhite}>
                {t("education_five__sub")}
              </div>
              <div className={classes.whatRowLeftList}>
                <p className="subtitle">{t("education_five__item1")}</p>
                <p className="subtitle">{t("education_five__item2")}</p>
                <p className="subtitle">{t("education_five__item3")}</p>
                <p className="subtitle">{t("education_five__item4")}</p>
              </div>
            </div>
            <div className={classes.whatRowRight}>
              <div className={classes.whatRowRightImg}>
                <img src={img1} alt="" />
              </div>
              <div
                className={[classes.whatRowRightImgTop, "coin_right"].join(" ")}
              >
                <img src={img2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default What;
