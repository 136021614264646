import React from "react";
import Header from "../../components/GlobalComponents/Header/Header";
import Banner from "../../components/MainPage/Banner/Banner"
import FogAnimation from "../../components/MainPage/FogAnimation/FogAnimation"
import OurAdvantages from "../../components/MainPage/OurAdvantages/OurAdvantages"
import TradeCFD from "../../components/MainPage/TradeCFD/TradeCFD"
import TopReasons from "../../components/MainPage/TopReasons/TopReasons"
import MoreForex from "../../components/MainPage/MoreForex/MoreForex"
import CanTrade from "../../components/MainPage/CanTrade/CanTrade"
import ChooseProminent from "../../components/MainPage/ChooseProminent/ChooseProminent"
import RangeTrading from "../../components/MainPage/RangeTrading/RangeTrading"
import StartApp from "../../components/MainPage/StartApp/StartApp"
import Footer from "../../components/GlobalComponents/Footer/Footer"

const MainPage = () => {
  return (
    <div>
      <div className="header__bg">
        <FogAnimation />
        <Header />
        <Banner />
      </div>
      <OurAdvantages />
      <TradeCFD />
      <section className="main__bg">
        <TopReasons />
        <MoreForex />
        <CanTrade />
        <ChooseProminent />
        <RangeTrading />
        <StartApp />
        <Footer />
      </section>
    </div>
  );
};

export default MainPage;
