import React from "react";
import classes from "./ManageWallet.module.scss";
import { useTranslation } from "react-i18next";
const ManageWallet = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.manageWallet}>
        <div className={classes.left}>
          <div className={classes.header}>{t("trading_three_left__title")}</div>
          <div className={classes.text}>
            <div>{t("trading_three_left__subtitle1")}</div>
            <div className={classes.par}>
              {t("trading_three_left__subtitle2")}
            </div>
            <div className={classes.par}>
              {t("trading_three_left__subtitle3")}
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.header}>
            {t("trading_three_right__title")}
          </div>
          <div className={classes.text}>
            {t("trading_three_right__subtitle")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageWallet;
