import React from "react";
import classes from "./FogAnimation.module.scss"

const FogAnimation = () => {
  return (
    <div>
      <div id={classes.foglayer_01} className={classes.fog}>
        <div className={classes.image01}></div>
        <div className={classes.image02}></div>
      </div>
      <div id={classes.foglayer_02} className={classes.fog}>
        <div className={classes.image01}></div>
        <div className={classes.image02}></div>
      </div>
      <div id={classes.foglayer_03} className={classes.fog}>
        <div className={classes.image01}></div>
        <div className={classes.image02}></div>
      </div>
    </div>
  )
}

export default FogAnimation;
