import React from "react";
import classes from "./AwardWinning.module.scss";
import { useTranslation } from "react-i18next";
const AwardWinning = () => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className={classes.awardWinning}>
        <div className={classes.left}>
          <div className={classes.block_01}>
            <div className={[classes.header, "header-default"].join(" ")}>
              {t("about_three_left__title1")}
            </div>
            <div className={classes.text}>
              {t("about_three_left__subtitle1")}
            </div>
          </div>
          <div className={classes.block_02}>
            <div className={[classes.header, "header-default"].join(" ")}>
              {t("about_three_left__title2")}
            </div>
            <div className={classes.text}>
              {t("about_three_left__subtitle2")}
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.block_03}>
            <div className={[classes.header, "header-default"].join(" ")}>
              {t("about_three_right__title")}
            </div>
            <div className={classes.text}>
              {t("about_three_right__subtitle")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwardWinning;
