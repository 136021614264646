import React, { useState } from "react";
import classes from "./Faq.module.scss";
import Spoiler from "../../GlobalComponents/Spoiler/Spoiler";

const Faq = () => {
  const [spoilers, setSpoilers] = useState([
    {
      id: 1,
      title: "accounts_five1__title",
      text: "accounts_five1__text",

      active: false,
    },
    {
      id: 2,
      title: "accounts_five2__title",
      text: "accounts_five2__text",
      active: false,
    },
    {
      id: 3,
      title: "accounts_five3__title",
      text: "accounts_five3__text",
      active: false,
    },
    {
      id: 4,
      title: "accounts_five4__title",
      text: "accounts_five4__text",
      active: false,
    },
    {
      id: 5,
      title: "accounts_five5__title",
      text: "accounts_five5__text",
      active: false,
    },
    {
      id: 6,
      title: "accounts_five6__title",
      text: "accounts_five6__text",
      active: false,
    },
    {
      id: 7,
      title: "accounts_five7__title",
      text: "accounts_five7__text",
      active: false,
    },
    {
      id: 8,
      title: "accounts_five8__title",
      text: "accounts_five9__text",
      active: false,
    },
    {
      id: 9,
      title: "accounts_five9__title",
      text: "accounts_five9__text",
      active: false,
    },
    {
      id: 10,
      title: "accounts_five10__title",
      text: "accounts_five10__text",
      active: false,
    },
  ]);

  return (
    <div className={classes.faq}>
      <div className={classes.faqBody}>
        <div className="container">
          <div className={[classes.faqTitle, "title"].join(" ")}>
            FAQ: Rules for depositing and withdrawing funds
          </div>
          <div className={classes.faqWrapper}>
            {spoilers.map((i, index) => {
              return (
                <Spoiler
                  {...i}
                  key={index}
                  clickEvent={() => {
                    setSpoilers(
                      spoilers.map((spoilerI) => {
                        if (spoilerI.id === i.id) {
                          return { ...spoilerI, active: !i.active };
                        }
                        return { ...spoilerI, active: false };
                      })
                    );
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
